import { useEffect, useState } from "react";
import {
  getLandscapeFontColor,
  getQueenLandscape,
  getSeason,
  getDarkerFontColor,
  getDarkestFontColor,
} from "../../publicFunctions/publicHelpers/homeHelper";
import FirstCTA from "./FirstCTA";
import LoadingSection from "../LoadingSection";
import {
  numberWithCommas,
  stateNames,
} from "../../publicFunctions/publicHelpers/helpers";

function Hero({ now, userInfo, openLogInHandler, battles, alreadyJoinedMap }) {
  const [isLoading, setIsLoading] = useState(true);
  const [prizeMoneyText, setPrizeMoneyText] = useState("");

  const season = getSeason(now);
  const fontColor = getLandscapeFontColor(season);

  useEffect(() => {
    if (battles?.length && userInfo && !userInfo.isStillCheckingLogin()) {
      // which battle should I get the prize text from?
      const currentBattle = battles[0];
      const nextBattle = battles.length > 1 ? battles[1] : null;
      let battleToUse = currentBattle;
      if (nextBattle && !alreadyJoinedMap.has(currentBattle.battleId)) {
        if (
          currentBattle.state !== stateNames.NOT_STARTED &&
          currentBattle.state !== stateNames.INITIALIZED
        ) {
          battleToUse = nextBattle;
        }
      }
      setPrizeMoneyText("$" + numberWithCommas(battleToUse.cashPrize));
    }
  }, [battles, alreadyJoinedMap, userInfo]);

  return (
    <section id="home-landing" className="section-hero queen-section">
      <img
        className="queen-landscape-background"
        src={getQueenLandscape(season)}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
      {isLoading ? (
        <LoadingSection />
      ) : (
        <>
          <div className="hero-cta-section">
            <div></div>
            <div className="hero-cta-container">
              <div className="hero-title-container">
                <h1 className="heading-primary heading-primary--smaller-mobile heading-left-side text-align-right">
                  Write
                  <br />
                  <span className="letter-spacing--smaller">Read</span>
                  <br />
                  <span className="letter-spacing--larger">Win</span>
                </h1>
                <h1 className="heading-primary heading-primary--smaller-mobile heading-right-side">
                  <span
                    style={{ color: getDarkestFontColor(fontColor) }}
                    className="accent--darker move-left letter-spacing--small"
                  >
                    one.
                  </span>
                  <br />
                  <span
                    style={{ color: getDarkerFontColor(fontColor) }}
                    className="accent--dark letter-spacing--larger"
                  >
                    ten.
                  </span>
                  <br />
                  <span
                    style={{ color: fontColor }}
                    className="accent letter-spacing--small"
                  >
                    thousands...
                  </span>
                </h1>
              </div>
              <FirstCTA
                isUnderHero={false}
                userInfo={userInfo}
                openLogInHandler={openLogInHandler}
                season={season}
              />
            </div>
          </div>
          {prizeMoneyText ? (
            <h1
              style={{ color: fontColor }}
              className="heading-primary queen-section-prize-text fade-in--slow"
            >
              {prizeMoneyText}
            </h1>
          ) : null}
        </>
      )}
    </section>
  );
}

export default Hero;
