import DefaultProfilePicture from "../img/profile_picture.png";
import { getTrophyImageUrlByFileName } from "../publicFunctions/publicHelpers/imageUrlBuilder";

function BasicUserInfo({ publicUserInfo }) {
  const trophyFileName = publicUserInfo?.trophiesDisplayed?.trophy1?.fileName;
  return (
    <a
      role="button"
      style={{ textDecoration: "none", color: "inherit" }}
      href={"/profile/" + publicUserInfo.pageName}
      target="_blank"
      onClick={(event) => {
        // event.preventDefault();
        event.stopPropagation();
        // window.open("/profile/" + publicUserInfo.pageName, "_blank");
      }}
      className="basic-user-info break-long-words"
    >
      <div className="basic-user-img-wrapper">
        <img
          className="basic-user-info--img"
          src={
            publicUserInfo?.profilePic
              ? publicUserInfo.profilePic
              : DefaultProfilePicture
          }
        />
        {trophyFileName ? (
          <div className="basic-user-trophy">
            <img
              className="basic-user-trophy--img"
              src={getTrophyImageUrlByFileName(trophyFileName)}
            />
          </div>
        ) : null}
      </div>
      <p className="description center-text mobile-wide-smaller-text">
        {publicUserInfo?.name ? publicUserInfo?.name : ""}
      </p>
    </a>
  );
}

export default BasicUserInfo;
