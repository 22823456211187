import { IonIcon } from "@ionic/react";
import {
  pencilOutline,
  bookOutline,
  paperPlaneOutline,
  trophyOutline,
} from "ionicons/icons";
import GlanceContainer from "./GlanceContainer";

function AtAGlance({
  initDate,
  submitDate,
  firstDuelDate,
  lastDuelDate,
  winnersDate,
  maxWords,
}) {
  return (
    <div className="glance-section">
      <div className="container grid grid--4-cols grid--center-h grid--glance">
        <GlanceContainer
          icon={pencilOutline}
          title="Start Writing"
          dates={[initDate]}
          isOdd={true}
          description="Receive and redraw your prompts, and start writing"
        />
        <GlanceContainer
          icon={paperPlaneOutline}
          title="Submit by"
          dates={[submitDate]}
          isOdd={false}
          description={"Submit an up-to " + maxWords + " word short story"}
        />
        <GlanceContainer
          icon={bookOutline}
          title="Read Stories"
          dates={[firstDuelDate, lastDuelDate]}
          isOdd={true}
          description="Read a total of 10 short stories over a 3 week period"
        />
        <GlanceContainer
          icon={trophyOutline}
          title="Victory"
          dates={[winnersDate]}
          isOdd={false}
          description="Winners are announced and all feedback is released"
        />
      </div>
    </div>
  );
}

export default AtAGlance;
