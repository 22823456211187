import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { getBattleInfo } from "../../publicFunctions/publicHelpers/helpers";
import { getIconByBattleTitle } from "../Pricing";
import { checkmarkOutline } from "ionicons/icons";

function SelectBattle({
  battles,
  selectedBattles,
  selectBattleHandler,
  alreadyJoinedMap,
  giftingMode,
}) {
  const [renderedBattles, setRenderedBattles] = useState([]);

  const selectedMap = new Map();
  for (const selected of selectedBattles) {
    selectedMap.set(selected.battleId, true);
  }

  const createRenderedBattles = (battleArray) => {
    return battleArray.map((battle, index) => {
      const isSelected = selectedMap.has(battle.battleId);

      const battleInfo = getBattleInfo(battle);
      getIconByBattleTitle(battleInfo.battleTitle);
      let title = battleInfo.battleTitleLong.replace(" Battle", "");
      const titleSplit = title.split(" ");
      if (titleSplit.length >= 3) {
        title = titleSplit[0] + " " + titleSplit[1] + "\n";
        const titleSlice = titleSplit.slice(2, titleSplit.length);
        title = title + titleSlice.join(" ");
      }

      const alreadyJoined =
        !giftingMode && alreadyJoinedMap.has(battle.battleId);
      let className = "battle-select-button";
      if (index === 0) className += " battle-select-soonest";
      if (isSelected) className += " battle-select-button--selected";
      if (alreadyJoined) className += " battle-select-button--alreadyJoined";

      const battleClicked = (battleObject) => {
        selectBattleHandler(battleObject);
      };

      return (
        <div
          key={battle.battleId}
          onClick={!alreadyJoined ? () => battleClicked(battle) : () => {}}
          role="button"
          tabIndex={-1}
        >
          <div className={className}>
            <div
              className="checked-border"
              style={{ visibility: alreadyJoined ? "hidden" : "visible" }}
            >
              <IonIcon
                className="jb-battle-icon battle-checked"
                icon={checkmarkOutline}
                style={{
                  visibility: isSelected ? "visible" : "hidden",
                }}
              />
            </div>
            <div className="flex jb-flex--gap-small justify-content--center">
              <IonIcon
                className="jb-battle-icon justify-self--center align-self--center"
                icon={getIconByBattleTitle(battleInfo.battleTitle)}
              />
              <p className="jb-battle-name center-text pre-wrap battle-name--select">
                {title}
              </p>
              <div className="jb-battle-icon"></div>
            </div>
            <p className="jb-description span--3 center-text">
              {battleInfo.shortDescription}
            </p>
            <div className="pricing-dollar-and-amount">
              <p className="dollar">$</p>
              <p className="battle-price">{battleInfo.currentPrice}</p>
              <p className="percent-off">
                {battleInfo.percentOff === "0"
                  ? "USD"
                  : "(" + battleInfo.percentOff + "% off)"}
              </p>
            </div>
            <p className="jb-description center-text bold">
              {alreadyJoined ? "Already Joined!" : battleInfo.startDate}
            </p>
          </div>
        </div>
      );
    });
  };

  const maxNumberOfBattles = battles.length > 4 ? 4 : battles.length;

  useEffect(() => {
    if (battles) {
      setRenderedBattles(
        createRenderedBattles(battles.slice(0, maxNumberOfBattles))
      );
    }
  }, [battles, selectedBattles, giftingMode]);

  return (
    <div className="grid grid--2-cols battle-select-grid">
      {renderedBattles}
    </div>
  );
}

export default SelectBattle;
