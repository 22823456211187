import QuestionAndAnswer from "./QuestionAndAnswer";

function FAQS() {
  return (
    <div className="faqs-section">
      <div className="container">
        <span className="subheading">Frequently Asked Questions</span>
      </div>
      <div className="container qAndA-list">
        <QuestionAndAnswer
          question="Do I retain the rights to my story?"
          answer="Yes, of course. It's your story.
           However, we kindly ask all of our winners and runner-ups to allow
           us to link to their winnings stories on our Past Winners page."
        />
        <QuestionAndAnswer
          question="Do I need to be a certain age to be eligible to enter?"
          answer="To be eligible to enter, participants must be at least 18 
          years old when the battle begins."
        />
        <QuestionAndAnswer
          question="When can I see the feedback that was left for my story?"
          answer="At the very end when the winners have been announced."
        />
        <QuestionAndAnswer
          question="How do you ensure that people judge fairly?"
          answer="Participants are never judging entries that their
           own story is in competition with since they only read entries
           in different genres than their own. We check all feedback as well
           as metadata to ensure that the stories are read and an adequate amount
           of time is spent on each story."
        />
        <QuestionAndAnswer
          question="Is this an international competition?"
          answer="Yes. We have writers from all around the world. Prize money will be
           converted to the currency of your choice."
        />
        <QuestionAndAnswer
          question="Does my story have to be in English?"
          answer="Your story should be primarily in English since the writers, readers,
           and judges are all English speakers. While you are welcome to include other
            languages in your writing, please keep in mind that the reader is assumed
             to understand English and nothing else."
        />
        <QuestionAndAnswer
          question="What are the genres for the upcoming Battle?"
          answer="Genres are kept secret until the start of the battle when the list of the four
          potential genres are emailed to everyone."
        />
        <QuestionAndAnswer
          question="What is the distribution method for the stories that I am assigned to read?"
          answer="Everything is on the website. Reading and judging is all done on the Battle page, 
          and the schedule for reading is provided in the Official Rules Agreement.
           The stories are made available two at-a-time formatted as a 'Duel' on the Battle page."
        />
        <QuestionAndAnswer
          question="Sorry... what the heck is a Duel again?"
          answer="A Duel is between two stories. It is a face-off, where a Judge reads both entries,
           gives feedback to each, and then chooses which one is 'stronger.'"
        />
        <QuestionAndAnswer
          question="Is it possible to draw the same prompt twice?"
          answer="No. We keep a history of all the prompts you have received during the current
           battle. You will never get the same prompt when re-drawing a card."
        />
        <QuestionAndAnswer
          question="Can I use the previous prompts after drawing a new one?"
          answer="No. In the spirit of the challenge of this competition, every time you draw
           cards you risk drawing a prompt even less inspiring than the one it replaced."
        />
        <QuestionAndAnswer
          question="Can I use an alias?"
          answer="Yes. Please check “Use an Alias” when editing your public profile. We will
           use the name in your public profile, or your alias if you prefer. Make sure you update
           your public profile before the winners of the battle are decided."
        />
        <QuestionAndAnswer
          question="One of the stories I read does not follow the Official Rules. What should I do?"
          answer="Email admin@writingbattle.com with the title of the story in question, and which
           rule you believe it violated."
        />
        <QuestionAndAnswer
          question="Is this all anonymous?"
          answer="Absolutely. The identities of the authors and judges shall remain anonymous throughout
           the Battle, ensuring complete confidentiality. When the peer judging has wrapped up,
           writers will be given the option to share their story with their fellow participants in a
           semi-public forum called DEBRIEF."
        />
        <QuestionAndAnswer
          question="What is a 'House?'"
          answer="During the five rounds of Duels, stories will only compete against other stories in their 
          same 'House' (all stories in a House are in the same Genre). Stories are randomly divided into 
          houses of no more than 50 stories. The stories that score 7 or more from each House in the same genre 
          will compete after the five rounds of Duels."
        />
        <QuestionAndAnswer
          question="How does judging work? What on earth are 'Spartans?'"
          answer="Each writer is also a judge! Writers never judge stories in the genres they are 
          competing in. In the Duels (first five rounds), stories compete only against others in their 
          own 'House'. Each story is read by 10 peer judges. In each Duel, your story is matched with a 
          different story. Each round of Duels will see your story go to TWO peer judges, thus across 
          the 5 duel periods, the story actually completes 10 Duels, against 10 different stories. 
          A story receives 1 point for winning a Duel, with maximum of 10 points.
          Stories that earn at least 7 points go on to compete in Duels against the top stories of 
          other Houses. It is still peer-judged, but by hundreds of so-called 'Spartans.' Spartans 
          are experienced members of the community who have completed at least one prior Battle. It 
          is a best of 5 system at this point. The same match-up of 2 stories are anonymously sent 
          to up to 5 judges, the one that wins at least 3, is declared the winner and moves up the bracket. 
          This continues until only a top 2 remain in each genre. The top 2 in each genre are sent 
          to our Industry Judges (established authors), and they determine the winner and runner-up 
          of each genre. Note that Debrief does not open until all Spartan judging is complete. 
          All peer-judging is completely anonymous."
        />
        <QuestionAndAnswer
          question="When and where does one see results, points?"
          answer="On the Debrief page, right at the end! All of the results are revealed in an 
          agonizingly fun way, step by step. Bit by bit, you get to see each story yours 
          duelled against, and which of the two stories won. Each time your story won, it 
          would have received 1 point. Your score out of 10 (5 rounds; 2 duels per round) is 
          now revealed. If your story earns at least 7 points in your group, you will advance to the 'Final Showdown'! 
          This means you compete against the top stories from other houses in your genre. The results 
          for this is always 2 days after the house results. Click on 'Final Showdown' in the Debrief 
          section."
        />
        <QuestionAndAnswer
          question="What is Debrief?"
          answer="If you choose, the end of the secrecy! Before results, but after Duels - while 
          the top 2 stories are being sorted into a winner and runner-up by professional judges - 
          Debrief is a period in which everyone has the option to post their stories to a secure 
          and private forum, then enjoy reading and commenting on the stories that were shared from 
          the Battle. At this stage, you also get to see which 'House' your story was in."
        />
        <QuestionAndAnswer
          question="How are the matches selected for the Duels?"
          answer="The first round, each story will be randomly matched with stories in its same 
          genre and its same 'House.' After the first round, and for all subsequent rounds, stories 
          shall be matched with other stories that have the same or similar points at the start of 
          the round. Thus, as the tournament progresses, the stories competing will be of increasingly 
          similar quality. This style of tournament is called 'Swiss' and is designed to sort the stories, 
          through multiple rounds. No story is eliminated from the five rounds of Swiss."
        />
        <QuestionAndAnswer
          question="What is the judging criteria? How do I decide between two equal stories?"
          answer="You are only ever comparing two stories and choosing a winner between the two. 
          Your choice should reflect which story you believe to be 'stronger,' but it's entirely 
          up to you. You're the judge and this is a pop culture contest! What do you like? If you're 
          having difficulty selecting a winner, then try finishing the feedback for each. If you have 
          time, then sleep on it. You must choose a winner."
        />
        <QuestionAndAnswer
          question="The story I'm reading seems to be competing in the wrong genre group. What now?"
          answer="People can get wonderfully creative with their interpretations of genre. 
          If, after careful consideration, you still think the story does not adhere to any resonable 
          definition of the genre, then email the admins of the contest (admin@writingbattle.com)"
        />
        <QuestionAndAnswer
          question="When can I share or talk about my story?"
          answer="As soon as Debrief begins. Revealing your title or story before Debrief 
          may result in disqualification from the Battle."
        />
        <QuestionAndAnswer
          question="Can we have a 'beta reader' (or editor) in this contest?"
          answer="Yes, but if your fellow beta reader is also in the Battle, then they 
          must be in the same Genre as your own story because then they would never be 
          assigned as a judge. During the writing period of a Battle, head on over to the 
          Forum and select the Beta Read tab -- you will see fellow battlers in your same 
          genre looking for beta readers."
        />
        <QuestionAndAnswer
          question="This seems like too much fun. What do you recommend I do?"
          answer="Join all of the battles and share the site with your friends and on social
           media to get the word out!"
        />
      </div>
    </div>
  );
}

export default FAQS;
