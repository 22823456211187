import DefaultBackOfGenre from "../../img/blue_back.webp";
import DefaultBackOfCard from "../../img/red_back.webp";
import { IonIcon } from "@ionic/react";
import { lockClosedSharp, lockOpenSharp } from "ionicons/icons";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";

function BattleCard({
  cardState,
  requestUnlock,
  position,
  cardIsBeingRedrawn,
  isWorking,
  forceLockCards,
  makeThisAButton,
  ownedBorder = false,
  promptSelector = false,
  isTavernCard = false,
}) {
  if (!cardState) return <div></div>;
  const isGenre =
    cardState.isGenre() && !cardState.isTavernCard() ? true : false;

  const isBlue = isGenre ? " blue" : "";
  const isLightBlue = isGenre ? " light-blue" : "";

  const isBlueBorder = isGenre ? " blue-card-border" : "";
  const isLightBlueBorder = isGenre ? " light-blue-card-border" : "";
  const isGreenBorder = ownedBorder ? " green-card-border" : "";

  return (
    <div role="button" tabIndex={-1} className="battle-card-container">
      {makeThisAButton ? null : cardIsBeingRedrawn ? (
        <div className="center-flex">
          <Lottie
            loop
            animationData={loadingAnimation}
            play
            style={{ width: "5rem", height: "5rem" }}
          ></Lottie>
        </div>
      ) : (
        <IonIcon
          onClick={() => {
            if (!isWorking && requestUnlock) {
              return requestUnlock(position);
            }
          }}
          className={
            cardState.isHardLocked
              ? "battle-icon hard-locked-icon" + isLightBlue
              : forceLockCards
              ? "battle-icon lock--noHover" + isBlue
              : "battle-icon" + isBlue
          }
          icon={
            cardState.isHardLocked || cardState.isLocked
              ? lockClosedSharp
              : lockOpenSharp
          }
        />
      )}
      <div
        onClick={() => {
          if (!isWorking && requestUnlock) {
            return requestUnlock(position);
          }
        }}
        className={
          ownedBorder
            ? "battle-card battle-card--new owned-card-border"
            : cardState.isHardLocked && !promptSelector
            ? "battle-card battle-card--new hard-locked-card" +
              isLightBlueBorder
            : cardState.isLocked && !forceLockCards
            ? "battle-card battle-card--new" + isBlueBorder
            : cardState.isLocked && forceLockCards
            ? "battle-card battle-card--new battle-card--noHover" + isBlueBorder
            : makeThisAButton
            ? "battle-card battle-card--unlocked battle-card--unlocked-clean"
            : cardIsBeingRedrawn || forceLockCards
            ? "battle-card battle-card--new battle-card--unlocked battle-card--noHover"
            : "battle-card battle-card--new battle-card--unlocked"
        }
        style={
          cardState.image
            ? { backgroundImage: 'url("' + cardState.image + '")' }
            : {
                backgroundImage:
                  'url("' +
                  (isGenre ? DefaultBackOfGenre : DefaultBackOfCard) +
                  '")',
              }
        }
      >
        <p
          className={
            cardState.image
              ? "battle-card--top-text"
              : "battle-card--top-text hidden"
          }
        >
          {cardState.type ? cardState.type : <span>&nbsp;&nbsp;</span>}
        </p>
        {!isGenre || isTavernCard ? (
          <p className="battle-card--bottom-text">
            {cardState.text ? cardState.text : <span>&nbsp;&nbsp;</span>}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default BattleCard;
