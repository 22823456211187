import { useEffect, useState } from "react";
import DefaultProfilePicture from "../../img/profile_picture.png";
import WBLogo from "../../img/logos/writingBattleLogoSupporting.webp";

function DebriefFeedback({ feedbackArray, myProfile }) {
  const [renderedFeedback, setRenderedFeedback] = useState([]);

  useEffect(() => {
    if (feedbackArray?.length) {
      const tempRender = [];
      for (let i = 0; i < feedbackArray.length; i++) {
        const feedback = feedbackArray[i];
        let feedbackText = "";
        if (feedback.liked) {
          feedbackText += "What I liked about the story:\n" + feedback.liked;
          feedbackText += "\n\n";
        }
        if (feedback.needsWork) {
          feedbackText +=
            "What I felt might need work with the story:\n" +
            feedback.needsWork;
          feedbackText += "\n\n";
        }

        tempRender.push(
          <div
            key={i.toString(10) + "_feedback"}
            className="leave-a-comment-section comment-container"
          >
            <div
              className={
                "flex flex--direction-column align-items--center margin-bottom-sm"
              }
            >
              <img
                className="basic-user-info--img"
                src={DefaultProfilePicture}
              />
            </div>
            <p className="description profile-text">{feedbackText}</p>
          </div>
        );
      }
      setRenderedFeedback(tempRender);
    }
  }, [feedbackArray]);

  return (
    <div className="container">
      <div className="debrief-feedback-list">
        <div className="leave-a-comment-section comment-container">
          <div
            className={
              "flex flex--direction-column align-items--center margin-bottom-sm"
            }
          >
            <img className="basic-logo--img" src={WBLogo} />
          </div>
          <p className="description profile-text">
            Hi{" "}
            {myProfile?.publicProfile?.name
              ? myProfile.publicProfile.name
              : "there"}
            ,
            <br />
            <br />
            The following feedback is from your anonymous peers during the Swiss
            Rounds of the tournament. We have intentionally shuffled the order
            that the feedback is presented.
            <br />
            <br />
            Remember that these are not trained writing judges or creative
            writing gurus. They are folks like yourself! People that enjoy
            writing and were brave enough to enter a contest that puts their
            creative endeavors online for people to read. For this reason, it is
            up to you to decide which piece of feedback to take to heart, and
            which to ignore. Opinions will vary, and not all stories will
            connect with all people.
            <br />
            <br />
            It is easy to get attached to the stories you write, but it's
            important to note that you completed this story in a very short
            amount of time with {"(likely)"} some pretty weird prompts. You
            should feel proud for rising to the challenge and submitting a
            story! Not everyone can do that!
            <br />
            <br />
            We have done our best to weed out anything insulting or vulgar, but
            mistakes on our part can happen. Please let us know if we missed
            something by emailing us at admin@writingbattle.com
            <br />
            <br />
            Congrats on completing a Battle and we hope to see you in the next
            one!
            <br />
            <br />
            Sincerely,
            <br />
            -The Writing Battle Team
          </p>
        </div>
        {renderedFeedback}
      </div>
    </div>
  );
}

export default DebriefFeedback;
