function DebriefBodyNav({
  selectPageHandler,
  selectedPage,
  showFinalShowdown,
  showHouses,
  showFeedback,
  isTavern,
}) {
  const selectableItemClass = "heading-tertiary debrief-nav-item";
  let storiesClass = selectableItemClass;
  let housesClass = selectableItemClass;
  let finalShowdownClass = selectableItemClass;
  let feedbackClass = selectableItemClass;
  let createClass = selectableItemClass;
  let collectionClass = selectableItemClass;
  let raidClass = selectableItemClass;
  if (selectedPage === "stories") {
    storiesClass += " debrief-nav-item--selected";
  } else if (selectedPage === "houses") {
    housesClass += " debrief-nav-item--selected";
  } else if (selectedPage === "finalShowdown") {
    finalShowdownClass += " debrief-nav-item--selected";
  } else if (selectedPage === "feedback") {
    feedbackClass += " debrief-nav-item--selected";
  } else if (selectedPage === "create") {
    createClass += " debrief-nav-item--selected";
  } else if (selectedPage === "collection") {
    collectionClass += " debrief-nav-item--selected";
  } else if (selectedPage === "raids") {
    raidClass += " debrief-nav-item--selected";
  }

  return (
    <div className="container debrief-body-nav">
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          selectPageHandler("stories");
        }}
      >
        <p className={storiesClass}>Stories</p>
      </div>
      {showHouses ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("houses");
          }}
        >
          <p className={housesClass}>Houses</p>
        </div>
      ) : null}
      {showFinalShowdown ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("finalShowdown");
          }}
        >
          <p className={finalShowdownClass}>Final Showdown</p>
        </div>
      ) : null}
      {showFeedback ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("feedback");
          }}
        >
          <p className={feedbackClass}>Feedback</p>
        </div>
      ) : null}
      {isTavern ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("create");
          }}
        >
          <p className={createClass}>Create / Update</p>
        </div>
      ) : null}
      {isTavern ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("collection");
          }}
        >
          <p className={collectionClass}>Collection</p>
        </div>
      ) : null}
      {isTavern ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("raids");
          }}
        >
          <p className={raidClass}>Raids</p>
        </div>
      ) : null}
    </div>
  );
}

export default DebriefBodyNav;
