import { useState } from "react";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline, caretDownOutline } from "ionicons/icons";

function PromptRules({ genreDefinition, widthOverride }) {
  const [isOpen, setIsOpen] = useState(false);

  let arrow;

  if (isOpen)
    arrow = <IonIcon className="faq-arrow-icon" icon={caretDownOutline} />;
  else
    arrow = <IonIcon className="faq-arrow-icon" icon={caretForwardOutline} />;

  const clickedHandler = (e) => {
    setIsOpen(!isOpen);
  };

  let answerClass = "answer ";
  if (widthOverride) {
    answerClass += "answer--width-override ";
  }

  return (
    <div
      onClick={clickedHandler}
      className="question-and-answer gray-background"
    >
      <div className={isOpen ? "description bold" : "description question"}>
        <div className="question-container">
          {arrow}
          <p>Click here to expand prompt rules and definitions</p>
        </div>
      </div>
      {isOpen ? (
        <div className="max-width--readable-center padding--small">
          <p className="description">Please note the following:</p>
          <ul className="indent">
            <li>
              <p className="description--small">
                The prompts are the TEXT and not necessarily the images.
              </p>
            </li>
            <li>
              <p className="description--small">
                Ignore the race, gender, or time period the images display.
              </p>
            </li>
            <li>
              <p className="description--small">
                The exact wording of a prompt may not appear in the story and
                does <strong>NOT</strong> have to.
              </p>
            </li>
          </ul>
          <br />

          {genreDefinition ? (
            <>
              <p className="description underline">Genre Definition:</p>
              <p className="description--small">{genreDefinition}</p>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default PromptRules;
