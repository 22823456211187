import {
  getKingForSeason,
  getPageForSeason,
  getQueenForSeason,
  getSeason,
} from "../../publicFunctions/publicHelpers/homeHelper";
import Reveal from "../animations/Reveal";

function HowItWorksSection({ now }) {
  const season = getSeason(now);
  return (
    <section className="section-how" id="howItWorks">
      <div className="container how-container">
        <Reveal type="fromBottom" rotate={true}>
          <span className="subheading">How it works</span>
        </Reveal>
        <Reveal type="fromBottom" rotate={true}>
          <h2 className="heading-secondary">
            A writing battle in 3 simple steps
          </h2>
        </Reveal>
      </div>
      <div className="container grid grid--2-cols grid--center-v how-container">
        <Reveal type="fromLeft" rotate={true} withBounce={false}>
          <div className="step-text-box">
            <p className="step-number">01</p>
            <h3 className="heading-tertiary">Receive writing prompts</h3>
            <p className="description">
              The battle begins with prompts being dealt to you. The prompts
              must be incorporated into your story. Use your limited redraws to
              swap them until you feel inspired.
            </p>
          </div>
        </Reveal>
        <div className="step-img-box">
          <Reveal type="fromRight" rotate={true} withBounce={false}>
            <img src={getPageForSeason(season)} className="step-img" alt="" />
          </Reveal>
        </div>
        <Reveal type="fromLeft" rotate={true} withBounce={false}>
          <div className="step-img-box">
            <img
              src={getQueenForSeason(season)}
              className="step-img--left"
              alt=""
            />
          </div>
        </Reveal>
        <Reveal type="fromRight" rotate={true} withBounce={false}>
          <div className="step-text-box">
            <p className="step-number">02</p>
            <h3 className="heading-tertiary">Write one story</h3>
            <p className="description">
              In a limited amount of time, rise to the challenge and submit your
              best story using the prompts. Your story will only compete against
              others in its same genre.
            </p>
          </div>
        </Reveal>
        <Reveal type="fromLeft" rotate={true} withBounce={false}>
          <div className="step-text-box">
            <p className="step-number">03</p>
            <h3 className="heading-tertiary">Read ten stories</h3>
            <p className="description">
              Whew! Now that you've finished writing, over the next few weeks,
              read ten short stories from different genres than your own. Elect
              winners and help progress a peer-powered tournament.
            </p>
          </div>
        </Reveal>
        <div className="step-img-box">
          <Reveal type="fromRight" rotate={true} withBounce={false}>
            <img src={getKingForSeason(season)} className="step-img" alt="" />
          </Reveal>
        </div>
      </div>
    </section>
  );
}

export default HowItWorksSection;
