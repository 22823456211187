import { useState, useEffect } from "react";
import JudgeCardList from "./JudgeCardList";
import { getIndustryJudges } from "../../publicFunctions/publicBattleHandler";
import Reveal from "../animations/Reveal";

function JudgesSection() {
  const [judgeCards, setJudgeCards] = useState([]);
  // fetch four of the featured (where featured is set to true) judge cards
  useEffect(() => {
    getIndustryJudges().then((result) => {
      setJudgeCards(result);
    });
  }, []); // do this the first time this component is mounted

  return (
    <section className="section-judges">
      <div className="center-text">
        <Reveal type="fromBottom" fadeIn={true}>
          <span className="subheading">Industry Judges</span>
        </Reveal>
        <Reveal type="fromBottom" fadeIn={true}>
          <h2 className="heading-secondary bottom-margin--small">
            The top stories are judged by industry pros
          </h2>
        </Reveal>
      </div>
      <div className="grid judgeImgs-and-description">
        <p className="description judge-description max-width--readable-center">
          Once the top stories from each of the four genres have been selected,
          the industry judges take over and pick the four winners. The cash
          prize pool is split amongst the genre winners and runner-ups.
        </p>
        <JudgeCardList judgeCards={judgeCards} />
      </div>
    </section>
  );
}

export default JudgesSection;
