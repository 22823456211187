import Countdown from "./Countdown";

function TitleAndDate({ countdownDate, title, isOut, halfQueen }) {
  return (
    <div className="title-and-date-section fade-in">
      <div className="time-and-date-grid">
        <div
          style={{
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundImage: `url(${halfQueen})`,
            backgroundPosition: "center left",
          }}
          className="time-and-date-container"
        >
          <div
            style={{
              backgroundColor: "rgba(127, 168, 186, 0.40)",
            }}
            className="battle-date-title heading-secondary bold"
          >
            {/* <IonIcon className="battle-icon white-icon" icon={battleIcon} /> */}
            <div></div>
            {isOut ? "Story out of Battle" : title}
          </div>
          <div style={{ backgroundColor: "rgba(127, 168, 186, 0.40)" }}>
            <Countdown date={countdownDate} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleAndDate;
