import { useState } from "react";
import { findNextDuel } from "../../config/firebase";

function GetDuel({ battleId, onBattleIdChange, onLoadDuel }) {
  const [isWorking, setIsWorking] = useState(false);
  const [duelId, setDuelId] = useState("");
  const [message, setMessage] = useState("");
  const [duelObject, setDuelObject] = useState(null);

  const onDuelIdChange = (event) => {
    setDuelId(event.target.value);
  };

  const onGetNextDuel = async (event) => {
    event.preventDefault();
    try {
      if (!battleId) return;
      setIsWorking(true);
      setMessage("Finding your next Duel...");
      const { data } = await findNextDuel({ battleId });
      if (data?.duelId) {
        setDuelId(data.duelId);
        setDuelObject(data);
        setMessage("Duel ID populated. Click 'Load Duel'");
      } else if (data?.message) {
        setMessage(data.message);
      } else {
        setMessage("Maybe you're all done? Email admin@writingbattle.com");
      }
    } catch (err) {
      console.log(err);
      setMessage(
        "Hmmmm the servers might be busy. Please try again in a few minutes." +
          " If you continue to receive this error, please email admin@writingbattle.com"
      );
    } finally {
      setIsWorking(false);
    }
  };

  const onDownloadDuel = async (event) => {
    event.preventDefault();
    try {
      if (!duelId) return;
      setIsWorking(true);
      setMessage("Loading Duel...");
      if (duelObject) {
        onLoadDuel(duelObject);
      } else {
        const { data } = await findNextDuel({ duelId });
        setMessage("Woohoo! Got it!");
        setDuelObject(data);
        onLoadDuel(data);
      }
    } catch (err) {
      console.log(err);
      setMessage(
        "Hmmmm the servers might be busy. Please try again in a few minutes." +
          " If you continue to receive this error, please email admin@writingbattle.com"
      );
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <div className="get-duel">
      <div className="flex flex--direction-column flex--align-center register-form">
        {/* <div>
          <label htmlFor="admin-duel-battleId">
            <strong>Battle ID</strong> {"("}
            Do<strong> not</strong> change. Admin use only{")"}
            <br /> Refresh the page if you accidently change this value.
          </label>
          <input
            id="admin-duel-battleId"
            className="story-title-input"
            value={battleId}
            onChange={(event) => onBattleIdChange(event.target.value)}
          />
        </div> */}
        <h2
          style={{ marginBottom: "2rem" }}
          className="heading-secondary center-text"
        >
          Spartan and Official Judging
        </h2>
        <p className="description center-text max-width--readable-center">
          {message}
        </p>
        <button
          className="btn btn--register min-width--larger story-submission-btn"
          disabled={isWorking || duelId || !battleId}
          onClick={onGetNextDuel}
        >
          Find Next Duel
        </button>
        <div>
          <label htmlFor="admin-duel-duelId">
            <strong>Duel ID</strong> {"("}
            Do<strong> not</strong> change manually{")"}
            <br /> Click 'Find Next Duel' to populate this field.
          </label>
          <input
            id="admin-duel-duelId"
            className="story-title-input"
            value={duelId}
            onChange={onDuelIdChange}
          />
        </div>
        <button
          className="btn btn--register min-width--larger story-submission-btn"
          disabled={isWorking || !duelId}
          onClick={onDownloadDuel}
        >
          Load Duel
        </button>
      </div>
    </div>
  );
}

export default GetDuel;
