import { useEffect, useState } from "react";
import {
  getBattleInfo,
  stateNames,
} from "../../publicFunctions/publicHelpers/helpers";
import AddToCalendarWrapper from "../AddToCalendarWrapper";
import Reveal from "../animations/Reveal";

function MarkYourCalendar({ battles }) {
  const [nextBattleInfo, setNextBattleInfo] = useState(null);

  useEffect(() => {
    if (battles?.length) {
      // find the next Battle
      for (let i = 0; i < battles.length; i++) {
        const battle = battles[i];
        if (battle.state && battle.state === stateNames.NOT_STARTED) {
          setNextBattleInfo(getBattleInfo(battle));
          break;
        }
      }
    }
  }, [battles]);

  if (!nextBattleInfo) return null;
  return (
    <div className="container padding-bottom">
      <Reveal type="fromBottom" rotate={true}>
        <span className="subheading">Mark Your Calendar</span>
      </Reveal>
      <Reveal type="fromBottom" rotate={true}>
        <h2 className="heading-secondary margin-bottom-md">
          Set a calendar reminder about the next Battle
        </h2>
      </Reveal>
      <div className="margin-bottom-large">
        <Reveal type="fromLeft" rotate={true}>
          <AddToCalendarWrapper
            startDate={nextBattleInfo.rawTuple.initialDate}
            endDate={nextBattleInfo.rawTuple.submissionDate}
            title={"Write " + nextBattleInfo.battleTitle}
            description={nextBattleInfo.shortDescription}
          />
        </Reveal>
      </div>
    </div>
  );
}

export default MarkYourCalendar;
