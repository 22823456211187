import GenreBackOfCard from "../../img/blue_back_tiny.webp";
import PrompBackOfCard from "../../img/red_back_tiny.webp";
import { getGenreIcon } from "../../publicFunctions/publicHelpers/promptTypes";
import { IonIcon } from "@ionic/react";

function PromptsSection({ prompt1Info, prompt2Info }) {
  return (
    <div className="prompts-section">
      <div className="prompts-grid">
        <div className="prompt-integration">
          <div className="prompts-titles">
            <span className="subheading subheading--prompts">
              Genre and Prompts
            </span>
            <h2 className="heading-secondary prompt-rules-title">
              Prompt integration
            </h2>
          </div>
          <p className="description white">
            The prompts in your story should be <em>significant</em> if you want
            to impress more judges since they are your peers (who are also
            participating, but in different Genres than your story) and are
            likely to appreciate prompts that are better integrated.
          </p>
          <br />
          <p className="description white">
            The exact text does <strong>NOT</strong> have to appear in your
            story.
          </p>
          <p className="description white indent">
            For example, if your Character prompt was "Doctor," but you referred
            to her as a <em>Physician</em>, then that would be perfectly
            acceptable; or
          </p>
          <p className="description white indent">
            If your Object prompt was "Ammunition," but you only referenced a{" "}
            <em>Bullet</em> in your story, then that would also be acceptable.
          </p>
          <br />
          <br />
          <p className="description white">
            For the prompts, it's okay to colour outside of the lines! Have fun
            with them, but be aware of genre expectations.
          </p>
          <p className="description white indent">
            This is a pop culture contest. Part of the challenge of mob judging
            is being cognizant of the zeitgeist of genre expectations.{" "}
            <strong>Do your own research.</strong>
          </p>
          <br />
          <p className="description white">
            Your prompts are <strong>NOT</strong> the images on the cards.
            Ignore the race, gender, or time period the images display. Feel
            free to use them as inspiration, but you are only responsible for
            the <strong>TEXT</strong> on the bottom of the card.
          </p>
        </div>
        <div className="prompt-type" style={{ backgroundColor: "#aac5d1" }}>
          <img className="rules-card" src={GenreBackOfCard}></img>
          <div className="prompt-icon-and-title black">
            <IonIcon className="battle-icon" icon={getGenreIcon()} />
            <h3 className="heading-tertiary center-text">Genre</h3>
          </div>
          <p className="description">
            Genre is a special prompt that can only be redrawn once. There are
            always four genres that are revealed to everyone when the Battle
            begins. We tend to have fun with the genres. Check out the{" "}
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href="https://www.writingbattle.com/past-winners"
              className="clickable-text"
              target="_blank"
            >
              Past Winners
            </a>{" "}
            page to get an idea of the types of genres that we have in our
            Battles.
            <br />
          </p>
        </div>
        <div className="prompt-type" style={{ backgroundColor: "#ae7d7d" }}>
          <img className="rules-card" src={PrompBackOfCard}></img>
          <div className="prompt-icon-and-title black">
            <IonIcon className="battle-icon" icon={prompt1Info.icon} />
            <h3 className="heading-tertiary center-text">{prompt1Info.type}</h3>
          </div>
          <p className="description">{prompt1Info.description}</p>
        </div>
        <div className="prompt-type" style={{ backgroundColor: "#c9a9a9" }}>
          <img className="rules-card" src={PrompBackOfCard}></img>
          <div className="prompt-icon-and-title black">
            <IonIcon className="battle-icon" icon={prompt2Info.icon} />
            <h3 className="heading-tertiary center-text">{prompt2Info.type}</h3>
          </div>
          <p className="description">{prompt2Info.description}</p>
        </div>
      </div>
    </div>
  );
}

export default PromptsSection;
