import { useState } from "react";
import Comments from "./Comments";
import LeaveAComment from "./LeaveAComment";

function UploadedStoryCommentSection({
  uploadedStory,
  userInfo,
  isFromBattle,
}) {
  const [refresh, setRefresh] = useState(false);
  // alright, this has two components: leave a comment section
  // then it also has the list of comments

  const forceRefreshComments = () => {
    setRefresh(!refresh);
  };

  return (
    <div>
      <LeaveAComment
        userInfo={userInfo}
        storyId={uploadedStory.storyId}
        typeOfComments={uploadedStory.typeOfComments}
        forceRefreshComments={forceRefreshComments}
        isFromBattle={isFromBattle}
      />
      {uploadedStory?.storyId ? (
        <div style={{ margin: "2.4rem 0" }}>
          <Comments
            key={refresh}
            uploadedStory={uploadedStory}
            userInfo={userInfo}
            forceRefreshComments={forceRefreshComments}
          />
        </div>
      ) : null}
    </div>
  );
}

export default UploadedStoryCommentSection;
