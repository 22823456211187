import { useNavigate } from "react-router-dom";
import Participant1 from "../../img/participants/participant1_128.jpg";
import Participant2 from "../../img/participants/participant2_128.jpg";
import Participant3 from "../../img/participants/participant3_128.jpg";
import Participant4 from "../../img/participants/participant4_128.jpg";
import Participant5 from "../../img/participants/participant5_128.jpg";
import Participant6 from "../../img/participants/participant6_128.jpg";
import Reveal from "../animations/Reveal";
import {
  AUTUMN,
  SPRING,
  SUMMER,
  WINTER,
  getLandscapeFontColor,
  getSeason,
} from "../../publicFunctions/publicHelpers/homeHelper";

function FirstCTA({ isUnderHero, userInfo, openLogInHandler, now }) {
  const navigate = useNavigate();

  const season = getSeason(now);
  let btnClass;
  if (season === AUTUMN) {
    btnClass = "btn--full--autumn";
  } else if (season === WINTER) {
    btnClass = "btn--full--winter";
  } else if (season === SPRING) {
    btnClass = "btn--full--spring";
  } else if (season === SUMMER) {
    btnClass = "btn--full--summer";
  } else {
    btnClass = "btn--full--autumn";
  }

  return (
    <Reveal type="fromBottom" fadeIn={true}>
      <div className={isUnderHero ? "cta-under-hero" : "cta-on-hero"}>
        <p className="hero-description--landscape">
          The peer-powered short story competition where everyone receives
          oodles of feedback.
        </p>
        <div className="first-cta-buttons">
          <button
            onClick={
              !userInfo.isLoggedIn()
                ? () => {
                    openLogInHandler("register");
                    navigate("/join-battle");
                  }
                : () => navigate("/join-battle")
            }
            className={"btn btn--full margin-right-sm " + btnClass}
          >
            Join the Battle
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              const section = document.querySelector("#howItWorks");
              section.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
            className="btn btn--outline"
          >
            Learn more &darr;
          </button>
        </div>
        <div className="current-participants">
          <div className="participant-imgs">
            <img
              className="participant-img participant-img--black-border"
              src={Participant1}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant2}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant3}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant4}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant5}
              alt="Participant photo"
            />
            <img
              className="participant-img participant-img--black-border"
              src={Participant6}
              alt="Participant photo"
            />
          </div>
          <p className="participants-text">
            The <span>coolest</span> writing community ever.
          </p>
        </div>
      </div>
    </Reveal>
  );
}

export default FirstCTA;
