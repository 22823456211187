import { IonIcon } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

function DeleteAThing({ description, handleDelete }) {
  const [youSure, setYouSure] = useState(false);
  const [typeYes, setTypeYes] = useState("");
  const [message, setMessage] = useState(
    "Are you sure you want to delete this " + description + "?"
  );

  useEffect(() => {
    if (typeYes && handleDelete) {
      const lowerCaseYes = typeYes.toLowerCase();
      if (lowerCaseYes === "yes") {
        setMessage("Deleting " + description + "... the page will refresh.");
        setTimeout(() => {
          handleDelete();
        }, 2500);
      }
    }
  }, [typeYes]);

  const handleTypeYes = (event) => {
    setTypeYes(event.target.value);
  };

  return (
    <div className="delete-thing">
      {youSure ? (
        <div className="delete-you-sure">
          <p className="description bold">{message}</p>
          <div className="flex justify-content--end text-align-right flex--gap-smaller">
            <p className="description--small">Type YES to delete</p>
            <input
              value={typeYes}
              onChange={handleTypeYes}
              placeholder="YES"
            ></input>
          </div>
          <p className="description">
            or click{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                setYouSure(false);
              }}
            >
              HERE
            </span>{" "}
            to go back
          </p>
        </div>
      ) : (
        <div
          onClick={() => {
            setYouSure(true);
          }}
          className="delete-thing-icon"
        >
          <IonIcon className="social-icon" icon={trashOutline} />
        </div>
      )}
    </div>
  );
}

export default DeleteAThing;
