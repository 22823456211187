import { useState } from "react";
import {
  convertTimestampToObject,
  getSimpleTimeInUserTimeZone,
} from "../../publicFunctions/publicHelpers/helpers";
import BasicUserInfo from "../BasicUserInfo";
import EditAThing from "../forum/EditAThing";
import {
  deleteComment,
  updateComment,
} from "../../publicFunctions/forumHandler";
import DeleteAThing from "../forum/DeleteAThing";

function Comment({
  publicUser,
  comment,
  uploadedStory,
  storyId,
  canEdit,
  thisIsMyStory,
  forceRefresh,
}) {
  const [realContent, setRealContent] = useState(comment.comment);
  const [editedContent, setEditedContent] = useState(comment.comment);
  const [editing, setEditing] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  return (
    <div className="leave-a-comment-section comment-container">
      {publicUser ? <BasicUserInfo publicUserInfo={publicUser} /> : null}
      <div className="flex flex--direction-column">
        {canEdit || thisIsMyStory ? (
          <DeleteAThing
            description={"comment"}
            handleDelete={() => {
              deleteComment(
                comment.commentId,
                thisIsMyStory && !canEdit ? true : false,
                uploadedStory.storyId
              ).then((success) => {
                forceRefresh();
              });
            }}
          />
        ) : null}
        {comment.createdDate ? (
          <>
            <p className="description--smaller">
              {getSimpleTimeInUserTimeZone(
                convertTimestampToObject(comment.createdDate)
              )}
            </p>
            <br />
          </>
        ) : null}
        {editing ? (
          <textarea
            className="feedback-text-area text-area--resize-vertical"
            value={editedContent}
            onChange={(event) => {
              setEditedContent(event.target.value);
              setChangesMade(true);
            }}
          />
        ) : (
          <p className="description profile-text">{realContent}</p>
        )}
        <br />
        <br />

        {editing ? (
          <div className="flex justify-content--center">
            <button
              onClick={() => {
                updateComment(comment.commentId, editedContent)
                  .then(() => {
                    setRealContent(editedContent);
                  })
                  .finally(() => {
                    setEditing(false);
                    setChangesMade(false);
                  });
              }}
              className="btn btn--outline min-width--even-larger margin-top-bigger"
              disabled={!changesMade}
            >
              Update Comment
            </button>
          </div>
        ) : null}

        {/* EDIT */}
        {canEdit ? (
          <EditAThing
            toggleEditHandler={(shouldBeEditing) => {
              setEditing(shouldBeEditing);
            }}
            argEditing={editing}
            justifySelfEnd={true}
          />
        ) : null}
      </div>

      {storyId &&
      uploadedStory.battleId &&
      storyId !== uploadedStory.storyId ? (
        <p
          onClick={() => {
            window.open(
              "/story/debrief/" +
                // uploadedStory.battleId +
                // "/" +
                storyId +
                "?uploadedStory",
              "_blank"
            );
          }}
          className={
            "description underline cursor--pointer" +
            " align-self--end justify-self--end"
          }
        >
          Return Read
        </p>
      ) : null}
    </div>
  );
}

export default Comment;
