const PREVIEW_TEXT_CLASS =
  "description--large center-text preview-text--mobile";

// DEBRIEF
const DEBRIEF_PREVIEW_FIRST_TITLE = "Debrief";
const DEBRIEF_PREVIEW_SECOND_TITLE = "Share your story after the Battle";
const DEBRIEF_PREVIEW_TEXT = (
  <p className={PREVIEW_TEXT_CLASS}>
    After the anonymous peer-judging wraps up, get cozy and read the endless
    stories from writers that choose to securely share their story with the
    Battle community. With the click of a button, you may share your own story
    and get even more feedback! After last Battle, over{" "}
    <strong>1,300,000</strong> words of <em>additional</em> feedback were
    written. The total amount of feedback was over 4,000,000 words.
  </p>
);
const DEBRIEF_PREVIEW_FIRST_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2Fdebrief1.webp?alt=media";
const DEBRIEF_PREVIEW_SECOND_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2Fdebrief2.webp?alt=media";

// FORUMS
const FORUMS_PREVIEW_FIRST_TITLE = "Forums";
const FORUMS_PREVIEW_SECOND_TITLE = "Join thousands of other writers";
const FORUMS_PREVIEW_TEXT = (
  <p className={PREVIEW_TEXT_CLASS}>
    Chat with our Battle community about anything and everything writing!
    Joining at least one Battle per year gives you access to our supportive and
    engaging community of writers. Create a profile, promote your socials and
    bookstore, and show off your trophies that you earn in each Battle!
  </p>
);
const FORUMS_PREVIEW_FIRST_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2Fforum2.webp?alt=media";

const FORUMS_PREVIEW_SECOND_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2Fforum1.webp?alt=media";

// TAVERN
const TAVERN_PREVIEW_FIRST_TITLE = "Tavern";
const TAVERN_PREVIEW_SECOND_TITLE = "Collect cards and complete raids";
const TAVERN_PREVIEW_TEXT = (
  <p className={PREVIEW_TEXT_CLASS}>
    Upload any story on our secure site and get feedback from other writers. You
    can also earn cards by completing Raids (assigned prompts) and finishing
    Battles. Use the cards as the prompt images in your personal projects.
  </p>
);
const TAVERN_PREVIEW_FIRST_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2Ftavern2.webp?alt=media";

const TAVERN_PREVIEW_SECOND_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Newsletter%2Fpreviews%2Ftavern3.webp?alt=media";

export function getPreviewContents(page) {
  let firstTitle;
  let secondTitle;
  let previewText;
  let firstImage;
  let secondImage;
  if (page === "Debrief") {
    firstTitle = DEBRIEF_PREVIEW_FIRST_TITLE;
    secondTitle = DEBRIEF_PREVIEW_SECOND_TITLE;
    previewText = DEBRIEF_PREVIEW_TEXT;
    firstImage = DEBRIEF_PREVIEW_FIRST_IMAGE;
    secondImage = DEBRIEF_PREVIEW_SECOND_IMAGE;
  } else if (page === "Forum") {
    firstTitle = FORUMS_PREVIEW_FIRST_TITLE;
    secondTitle = FORUMS_PREVIEW_SECOND_TITLE;
    previewText = FORUMS_PREVIEW_TEXT;
    firstImage = FORUMS_PREVIEW_FIRST_IMAGE;
    secondImage = FORUMS_PREVIEW_SECOND_IMAGE;
  } else if (page === "Tavern") {
    firstTitle = TAVERN_PREVIEW_FIRST_TITLE;
    secondTitle = TAVERN_PREVIEW_SECOND_TITLE;
    previewText = TAVERN_PREVIEW_TEXT;
    firstImage = TAVERN_PREVIEW_FIRST_IMAGE;
    secondImage = TAVERN_PREVIEW_SECOND_IMAGE;
  }

  return {
    firstTitle,
    secondTitle,
    previewText,
    firstImage,
    secondImage,
  };
}
