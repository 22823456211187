import "../styles/joinBattle.css";
import "../styles/joinBattleQueries.css";
import { giftOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import SelectBattle from "../components/join/SelectBattles";
import SelectBattleController from "../components/join/SelectBattleController";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingSection from "../components/LoadingSection";
import ThankYou from "../components/join/ThankYou";
import WBLogo from "../img/WritingBattleLogo_800.webp";
import SEO from "../components/SEO";
import SwitchToGifting from "../components/join/SwitchToGifting";

function JoinBattle({
  battles,
  alreadyJoinedMap,
  switchToCheckout,
  credit,
  thankYouState,
  refreshMyProfile,
  closeCheckout,
  userInfo,
  openRegisterOverlay,
}) {
  const [selectedBattles, setSelectedBattles] = useState([]);
  const [battlesToShow, setBattlesToShow] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [giftingMode, setGiftingMode] = useState(
    searchParams && searchParams.has("giftingMode") ? true : false
  );
  const [showThankYouPage, setShowThankYouPage] = useState({
    show: false,
    success: false,
    gifted: giftingMode ? true : false,
  });
  const [hideGiftingMode, setHideGiftingMode] = useState(false);

  useEffect(() => {
    if (!battles || !battles.length) return;
    // find the battles that we actually want to show
    const maxNumberOfBattles = battles.length > 4 ? 4 : battles.length;
    const now = new Date();
    const arrayOfJoinableBattles = [];
    for (let i = 0; i < battles.length; i++) {
      const battle = battles[i];
      if (now < battle.lockInDate) {
        arrayOfJoinableBattles.push(battle);
      }
    }
    const tempShowBattles = arrayOfJoinableBattles.slice(0, maxNumberOfBattles);
    setBattlesToShow(tempShowBattles);
    if (
      searchParams.has("autoSelectBattleId") &&
      !alreadyJoinedMap.has(searchParams.get("autoSelectBattleId"))
    ) {
      const autoSelectedBattles = [];
      for (const battle of tempShowBattles) {
        if (battle.battleId === searchParams.get("autoSelectBattleId")) {
          autoSelectedBattles.push(battle);
          break;
        }
      }
      if (autoSelectedBattles.length) {
        setSelectedBattles(autoSelectedBattles);
        return;
      }
    }

    // try for the first four battles to pick a battle
    const defaultSelectedBattles = [];
    for (const battle of tempShowBattles) {
      if (!alreadyJoinedMap.has(battle.battleId)) {
        defaultSelectedBattles.push(battle);
        break;
      }
    }
    if (giftingMode) {
      setSelectedBattles([]);
    } else {
      setSelectedBattles(defaultSelectedBattles); // soonest
    }
  }, [battles, alreadyJoinedMap]);

  useEffect(() => {
    if (giftingMode) {
      setSelectedBattles([]);
    } else if (selectedBattles.length && alreadyJoinedMap?.size) {
      // switching out of gifting mode
      // de-select any Battles that you've already joined
      const tempSelectedBattles = [];
      for (let i = selectedBattles.length - 1; i >= 0; i--) {
        const selectedBattle = selectedBattles[i];
        if (
          !selectedBattle.battleId ||
          !alreadyJoinedMap.has(selectedBattle.battleId)
        ) {
          console.log(selectedBattle);
          tempSelectedBattles.push(selectedBattle);
        }
      }
      setSelectedBattles(tempSelectedBattles);
    }
  }, [giftingMode]);

  useEffect(() => {
    const tempThankYou = {
      show: thankYouState.finishedCheckout,
      success: thankYouState.successfulCheckout,
      gifted: giftingMode ? true : false,
    };
    if (thankYouState.finishedCheckout && thankYouState.successfulCheckout) {
      setHideGiftingMode(true);
    }
    setShowThankYouPage(tempThankYou);
  }, [thankYouState]);

  useEffect(() => {
    const autoLogin = searchParams.get("state");
    if (
      !userInfo.isStillCheckingLogin() &&
      !userInfo.isLoggedIn() &&
      autoLogin === "login"
    ) {
      openRegisterOverlay("login");
    } else if (!userInfo.isStillCheckingLogin() && !userInfo.isLoggedIn()) {
      openRegisterOverlay("register");
    }
  }, [userInfo]);

  const switchToThankYouPage = (toThankYou, success) => {
    const tempThankYou = {
      show: toThankYou,
      success: success,
      gifted: giftingMode ? true : false,
    };
    setHideGiftingMode(true);
    setShowThankYouPage(tempThankYou);
  };

  const selectBattleHandler = (selectedBattle) => {
    const newArray = Array.from(selectedBattles);

    // check array to see if it includes the selected Battles,
    // and if so, remove it.
    let battleAlreadyInArray = false;
    for (let i = 0; i < newArray.length; i++) {
      const battle = newArray[i];
      if (battle.battleId === selectedBattle.battleId) {
        newArray.splice(i, 1);
        battleAlreadyInArray = true;
        break;
      }
    }
    if (!battleAlreadyInArray) {
      newArray.push(selectedBattle);
    }

    newArray.sort((a, b) => {
      return a.initialDate.getTime() - b.initialDate.getTime();
    });

    setSelectedBattles(newArray);
  };

  const finishedLoading = battles && battles.length ? true : 0;

  const handleGiftingClicked = () => {
    setGiftingMode(!giftingMode);
  };

  return (
    <div className="hero-background">
      <SEO
        title="Join | Writing Battle"
        description="Sign up and register for the next short story Writing Battle!"
        name="Writing Battle"
        type="website"
      />
      {finishedLoading ? (
        <div>
          <div className="container heading--join-battle">
            <div className="flex flex--gap-normal flex--direction-column--mobile">
              <h1 className="heading-primary">
                {showThankYouPage.show
                  ? showThankYouPage.success
                    ? "Thank you!"
                    : "Hmmm..."
                  : giftingMode
                  ? "Gift Battles"
                  : "Join Battles"}
              </h1>
            </div>
          </div>
          <div className="join-battle">
            {showThankYouPage.show ? (
              <>
                <ThankYou
                  wasSuccessful={showThankYouPage.success}
                  resetThankYou={closeCheckout}
                  gifted={showThankYouPage.gifted}
                />
                <img className="thank-you-img" src={WBLogo}></img>
              </>
            ) : (
              <>
                <SelectBattle
                  battles={battlesToShow}
                  selectedBattles={selectedBattles}
                  selectBattleHandler={selectBattleHandler}
                  alreadyJoinedMap={alreadyJoinedMap}
                  giftingMode={giftingMode}
                />
                <SelectBattleController
                  selectedBattles={selectedBattles}
                  switchToCheckout={switchToCheckout}
                  credit={credit}
                  switchToThankYouPage={switchToThankYouPage}
                  refreshMyProfile={refreshMyProfile}
                  isLoggedIn={userInfo.isLoggedIn()}
                  giftingMode={giftingMode}
                />
              </>
            )}
          </div>
          {!hideGiftingMode ? (
            <SwitchToGifting
              giftingMode={giftingMode}
              handleGiftingClicked={handleGiftingClicked}
            />
          ) : null}
          {giftingMode ? (
            <div className="container margin-bottom-sm">
              <div className="flex flex--align-center flex--gap-normal">
                <div>
                  <IonIcon className="battle-icon--large" icon={giftOutline} />
                </div>
                <p className="description">
                  Each gift is in the form of a digital "ticket" containing a
                  link to register for the Battle. It is up to you to forward
                  the ticket to the gift-receiver. There is no expiration date,
                  as long as the ticket is used before the Battle begins. All
                  the recipient has to do is click the link on the ticket, and
                  they will be prompted to register with their email. Easy :)
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <LoadingSection />
      )}
    </div>
  );
}

export default JoinBattle;
