import { db } from "../config/firebase";
import {
  collection,
  query,
  where,
  limit,
  Timestamp,
  getDoc,
  doc,
} from "firebase/firestore";
import {
  stateNames,
  getQueryAsArray,
  getQueryAsArrayWithId,
  convertAllTimestampsToDatesInArray,
  convertAllTimestampsToDatesInObject,
} from "./publicHelpers/helpers";

export async function getIndustryJudges(numberOfJudges = 5) {
  const q = query(
    collection(db, "industryJudges"),
    where("featured", "==", true),
    limit(numberOfJudges)
  );
  return await getQueryAsArrayWithId(q);
}

export async function getRelevantBattles() {
  const q = query(
    collection(db, "battles"),
    where("state", "!=", stateNames.COMPLETE)
  );
  const tuples = await getQueryAsArray(q);
  convertAllTimestampsToDatesInArray(tuples);

  // sort them
  tuples.sort((a, b) => {
    return a.initialDate.getTime() - b.initialDate.getTime();
  });
  return tuples;
}

export function extractItemsToPurchaseFromBattles(listOfBattles) {
  const toReturn = [];
  for (const battle of listOfBattles) {
    toReturn.push(battle.battleId);
  }
  return toReturn;
}

export async function getBattleByBattleId(battleId) {
  const snap = await getDoc(doc(db, "battles", battleId));
  if (snap.exists) {
    const data = snap.data();
    convertAllTimestampsToDatesInObject(data);
    return data;
  } else {
    return null;
  }
}
