import { useEffect, useRef, useState } from "react";
import PromptSelector from "./PromptSelector";
import CardSelector from "./CardSelector";
import { pageScrollSettings } from "../../publicFunctions/publicHelpers/helpers";
import { getTavernCard } from "../../publicFunctions/tavernHandler";

function StoryPromptsSelector({
  myProfile,
  myCards,
  collectionMeta,
  requestCollectionMeta,
  requestMyCards,
  promptCardHandler,
  loadedStory,
  choosePromptTitleRef,
  promptMessage,
  raidObject,
}) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [firstLoadedCard, setFirstLoadedCard] = useState(
    raidObject?.cards?.length >= 3 ? getLoadedRaidCard(raidObject, 0) : null
  );
  const [secondLoadedCard, setSecondLoadedCard] = useState(
    raidObject?.cards?.length >= 3 ? getLoadedRaidCard(raidObject, 1) : null
  );
  const [thirdLoadedCard, setThirdLoadedCard] = useState(
    raidObject?.cards?.length >= 3 ? getLoadedRaidCard(raidObject, 2) : null
  );
  const topRef = useRef(null);
  const firstCardRef = useRef(null);
  const secondCardRef = useRef(null);
  const thirdCardRef = useRef(null);

  useEffect(() => {
    if (loadedStory) {
      if (loadedStory.cards) {
        const cards = loadedStory.cards;
        const card1 = cards.genre;
        const card2 = cards.prompt1;
        const card3 = cards.prompt2;
        getTavernCard(card1.id)
          .then((tempCard) => {
            const tempLoadedCard = {
              text: tempCard.defaultText,
              type: tempCard.defaultType,
              id: tempCard.id,
              imageFileName: tempCard.imageFileName,
              definition: tempCard.defaultDefinition
                ? tempCard.defaultDefinition
                : "",
            };
            setFirstLoadedCard(tempLoadedCard);
          })
          .catch((err) => {
            console.log(err);
          });
        getTavernCard(card2.id)
          .then((tempCard) => {
            const tempLoadedCard = {
              text: tempCard.defaultText,
              type: tempCard.defaultType,
              id: tempCard.id,
              imageFileName: tempCard.imageFileName,
              definition: tempCard.defaultDefinition
                ? tempCard.defaultDefinition
                : "",
            };
            setSecondLoadedCard(tempLoadedCard);
          })
          .catch((err) => {
            console.log(err);
          });
        getTavernCard(card3.id)
          .then((tempCard) => {
            const tempLoadedCard = {
              text: tempCard.defaultText,
              type: tempCard.defaultType,
              id: tempCard.id,
              imageFileName: tempCard.imageFileName,
              definition: tempCard.defaultDefinition
                ? tempCard.defaultDefinition
                : "",
            };
            setThirdLoadedCard(tempLoadedCard);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      // TODO: (important) CLEAR EVERYTHING
    }
  }, [loadedStory]);

  const selectedCardHandler = async (cardId) => {
    const currentSelectedIndex = selectedIndex;
    if (currentSelectedIndex !== -1) {
      getTavernCard(cardId)
        .then((tempCard) => {
          const tempLoadedCard = {
            text: tempCard.defaultText,
            type: tempCard.defaultType,
            id: tempCard.id,
            imageFileName: tempCard.imageFileName,
            definition: tempCard.defaultDefinition
              ? tempCard.defaultDefinition
              : "",
          };
          if (currentSelectedIndex === 0) {
            setFirstLoadedCard(tempLoadedCard);
          } else if (currentSelectedIndex === 1) {
            setSecondLoadedCard(tempLoadedCard);
          } else if (currentSelectedIndex === 2) {
            setThirdLoadedCard(tempLoadedCard);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      setSelectedIndex(-1);
    }
  };

  useEffect(() => {
    if (selectedIndex !== -1) {
      if (topRef?.current) {
        topRef.current.scrollIntoView(pageScrollSettings);
      }
    }
  }, [selectedIndex]);

  return (
    <div className="brown-background--lighter margin-top-bigger padding--md">
      <h2
        ref={choosePromptTitleRef}
        className="heading-secondary center-text margin--none"
      >
        {raidObject?.name
          ? "Raid: " + raidObject?.name
          : "Step 1 of 3: Choose Prompt Cards"}
      </h2>
      {raidObject?.definition ? (
        <p className="description--small italic center-text">
          {raidObject.definition}
        </p>
      ) : null}
      {promptMessage ? (
        <p className="description bold red center-text">{promptMessage}</p>
      ) : null}
      <br />
      <br />
      <div className="prompt-selector-cards flex justify-content--center flex--gap-smaller align-items--center">
        <PromptSelector
          componentName={"first"}
          highlight={selectedIndex === 0}
          clickHandler={() => {
            if (!raidObject) {
              setSelectedIndex(0);
            }
          }}
          reference={firstCardRef}
          loadedCard={firstLoadedCard}
          promptCardHandler={promptCardHandler}
          loadedStory={loadedStory}
          raidObject={raidObject}
        />
        <PromptSelector
          componentName={"second"}
          highlight={selectedIndex === 1}
          clickHandler={() => {
            if (!raidObject) {
              setSelectedIndex(1);
            }
          }}
          reference={secondCardRef}
          loadedCard={secondLoadedCard}
          promptCardHandler={promptCardHandler}
          loadedStory={loadedStory}
          raidObject={raidObject}
        />
        <PromptSelector
          componentName={"third"}
          highlight={selectedIndex === 2}
          clickHandler={() => {
            if (!raidObject) {
              setSelectedIndex(2);
            }
          }}
          reference={thirdCardRef}
          loadedCard={thirdLoadedCard}
          promptCardHandler={promptCardHandler}
          loadedStory={loadedStory}
          raidObject={raidObject}
        />
      </div>
      {selectedIndex !== -1 ? (
        <div ref={topRef} className="flex flex--direction-column margin-auto">
          <p className="description--larger bold center-text underline margin-top-bottom-md">
            Select a prompt card:
          </p>
          <CardSelector
            collectionMeta={collectionMeta}
            requestCollectionMeta={requestCollectionMeta}
            requestMyCards={requestMyCards}
            myCards={myCards}
            myProfile={myProfile}
            selectedCardHandler={selectedCardHandler}
            neverShowUnownedCards={true}
            genreFirst={selectedIndex === 0}
            hideDisplayCard={true}
            promptSelecting={true}
          />
        </div>
      ) : null}
    </div>
  );
}

function getLoadedRaidCard(raidObject, cardIndex) {
  const definition =
    cardIndex === 0 && raidObject.definition ? raidObject.definition : "";
  const card = raidObject.cards[cardIndex];
  return {
    text: card.text,
    type: card.type,
    id: card.id,
    imageFileName: card.imageFileName,
    definition: definition ? definition : "",
  };
}

export default StoryPromptsSelector;
