const ONE_WEEK = 604800000;
const ONE_MONTH = 2592000000;
const TWENTY_FOUR_HOURS = 86400000;
const EIGHTEEN_HOURS = 64800000;
const ONE_HOUR = 3600000;

export function saveDataToLocalStorageWithExpiry(
  key,
  data,
  isLongExpiry = false,
  isSuperLongExpiry = false,
  aLittleLessThanADay = false,
  makeItAMonth = false
) {
  let expiryPeriod;
  if (isSuperLongExpiry) {
    if (makeItAMonth) {
      expiryPeriod = ONE_MONTH;
    } else {
      expiryPeriod = ONE_WEEK;
    }
  } else if (isLongExpiry) {
    if (aLittleLessThanADay) {
      expiryPeriod = EIGHTEEN_HOURS;
    } else {
      expiryPeriod = TWENTY_FOUR_HOURS;
    }
  } else {
    expiryPeriod = ONE_HOUR;
  }
  const now = new Date();
  const item = {
    data: data,
    expiry: now.getTime() + expiryPeriod,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getDataFromLocalStorageWithExpiry(key) {
  const item = localStorage.getItem(key);
  if (item) {
    const parsedItem = JSON.parse(item);
    const now = new Date();
    const expiry = new Date(parsedItem.expiry);

    if (now.getTime() < expiry.getTime()) {
      return parsedItem.data; // Return data if not expired
    } else {
      localStorage.removeItem(key); // Remove expired data from localStorage
    }
  }
  return null;
}
