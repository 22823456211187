import PublicProfile from "./PublicProfile";

class MyProfile {
  constructor() {
    this.userId = "";
    this.publicId = "";
    this.email = "";
    this.trophyBank = [];
    this.wallet = {
      credit: 0,
    };
    this.battleStories = [];
    this.uploads = [];
    this.pictures = {
      originalProfilePic: "",
    };
    this.names = {
      firstName: "",
      lastName: "",
      useAlias: false,
      alias: "",
    };
    this.notificationPreferences = {
      newsletter: true,
    };
    this.settings = {
      hideContentWarnings: false,
    };
    this.publicProfile = new PublicProfile();
  }

  setProfile(privateInfo, publicInfo) {
    // private
    this.userId = privateInfo.userId;
    this.publicId = privateInfo.publicId;
    this.email = privateInfo.email;
    this.trophyBank = privateInfo.trophyBank;
    this.wallet = privateInfo.wallet;
    this.battleStories = privateInfo.battleStories;
    this.uploads = privateInfo.uploads;
    this.pictures = privateInfo.pictures;
    this.names = privateInfo.names;
    this.notificationPreferences = privateInfo.notificationPreferences;
    const settings = privateInfo.settings ? privateInfo.settings : {};
    this.settings = settings;

    // public
    this.publicProfile.setPublicProfile(publicInfo);
  }

  isInitialized() {
    return this.userId ? true : false;
  }
}

export default MyProfile;
