import { IonIcon } from "@ionic/react";
import {
  calendarOutline,
  cashOutline,
  bookmarkOutline,
  happyOutline,
  flowerOutline,
  flashOutline,
  sunnyOutline,
  leafOutline,
  snowOutline,
} from "ionicons/icons";
import { useNavigate } from "react-router-dom";

function Pricing({ className, battleInfo, openLogInHandler, isLoggedIn }) {
  const navigate = useNavigate();
  return (
    <div className={"pricing-battle " + className}>
      <header className="battle-header">
        <div className="pricing-name-and-logo">
          <IonIcon
            className="battle-icon"
            icon={getIconByBattleTitle(battleInfo.battleTitle)}
          />
          <p className="battle-name">{battleInfo.battleTitle}</p>
        </div>
        <p className="battle-text">{battleInfo.shortDescription}</p>
        <div className="pricing-dollar-and-amount">
          <p className="dollar">$</p>
          <p className="battle-price">{battleInfo.currentPrice}</p>
          <p className="percent-off">
            {battleInfo.percentOff === "0"
              ? "USD"
              : "(" + battleInfo.percentOff + "% off)"}
          </p>
        </div>
        <p className="pricing-discount">
          {battleInfo.percentOff === "0"
            ? "Prices includes all applicable taxes"
            : "Entry fee increases to $" +
              battleInfo.finalPrice +
              " on " +
              battleInfo.earlyEntryDeadline}
        </p>
      </header>
      <ul className="list">
        <li className="list-item">
          <IonIcon className="list-icon" icon={calendarOutline} />
          <span>{battleInfo.startDate}</span>
        </li>
        <li className="list-item">
          <IonIcon className="list-icon" icon={cashOutline} />
          <span>{battleInfo.cashText + " cash prize pool"}</span>
        </li>
        <li className="list-item">
          <IonIcon className="list-icon" icon={bookmarkOutline} />
          <span>Winners are published</span>
        </li>
        <li className="list-item">
          <IonIcon className="list-icon" icon={happyOutline} />
          <span>Oodles of feedback</span>
        </li>
      </ul>
      <div className="battle-sign-up">
        <button
          className={
            "btn btn" + (className.includes("next") ? "--outline" : "--full")
          }
          onClick={
            !isLoggedIn
              ? () => {
                  navigate(
                    "/join-battle?autoSelectBattleId=" +
                      battleInfo.rawTuple.battleId
                  );
                }
              : () =>
                  navigate(
                    "/join-battle?autoSelectBattleId=" +
                      battleInfo.rawTuple.battleId
                  )
          }
        >
          Register
        </button>
      </div>
    </div>
  );
}

export function getIconByBattleTitle(battleTitle) {
  let formattedBattleTitle = "";
  if (battleTitle) {
    formattedBattleTitle = battleTitle.toLowerCase();
  }
  if (formattedBattleTitle.includes("spring")) return flowerOutline;
  else if (formattedBattleTitle.includes("summer")) return sunnyOutline;
  else if (formattedBattleTitle.includes("autumn")) return leafOutline;
  else if (formattedBattleTitle.includes("winter")) return snowOutline;
  else return flashOutline;
}

export default Pricing;
