import { useEffect, useState } from "react";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import DefaultProfilePicture from "../../img/profile_picture.png";
import { IonIcon } from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";

function SelectUserButton({
  userId,
  clickingRemovesMe,
  handleWhatHappensWhenYouClickMe,
  isReadOnly,
}) {
  const [publicUser, setPublicUser] = useState(null);

  useEffect(() => {
    if (userId) {
      getPublicProfileByUserId(userId)
        .then((publicUser) => {
          if (publicUser) {
            setPublicUser(publicUser);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  let editedName = "";
  if (publicUser?.name) {
    editedName = publicUser.name.slice(0, 8);
    if (publicUser.name.length > 8) {
      editedName += "...";
    }
  }

  return (
    <div>
      {publicUser ? (
        <div
          role="button"
          className={
            "select-user-button flex align-items--center flex--gap-tiny" +
            (clickingRemovesMe && !isReadOnly
              ? " green-background white-background-on-hover"
              : clickingRemovesMe && isReadOnly
              ? " cursor--auto box-shadow--none"
              : " green-background-on-hover")
          }
          onClick={() => {
            handleWhatHappensWhenYouClickMe(
              publicUser.userId,
              clickingRemovesMe
            );
          }}
        >
          <img
            className="basic-user-info--img-smaller"
            src={
              publicUser.profilePic
                ? publicUser.profilePic
                : DefaultProfilePicture
            }
          />
          <p className="description--smaller description--smaller-set-width">
            {editedName}
          </p>
          {!isReadOnly ? (
            <IonIcon
              className="social-icon"
              icon={clickingRemovesMe ? closeOutline : addOutline}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default SelectUserButton;
