function OfficialRulesPDF({ officialRulesLink }) {
  return (
    <div className="official-rules-section">
      <div className="container center-text">
        <span className="subheading">
          Click the link below to download a copy of the participant agreement
        </span>
        <h2 className="heading-secondary">
          <a
            className="official-rules"
            target="_blank"
            href={officialRulesLink}
          >
            Official Rules Agreement
          </a>
        </h2>
      </div>
    </div>
  );
}

export default OfficialRulesPDF;
