import { useEffect, useState } from "react";
import BackOfCard from "../../img/back_of_card_full.webp";
import BackOfGenreCard from "../../img/back_of_genre_full.webp";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import BasicUserInfo from "../BasicUserInfo";
import CardState from "../../publicFunctions/clientClasses/CardState";
import BattleCard from "../battle/BattleCard";
import { getFlagImageUrlByFlagFileName } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import PromptRules from "./PromptRules";

function StoryInfo({
  cards,
  storyTitle,
  contentWarning,
  hideContentWarnings,
  uploadedStory,
  isLoggedIn,
  genreDefinition,
}) {
  const [publicUser, setPublicUser] = useState(null);
  useEffect(() => {
    if (uploadedStory?.userId) {
      getPublicProfileByUserId(uploadedStory.userId)
        .then((result) => {
          setPublicUser(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [uploadedStory, isLoggedIn]);
  const promptTypeGenre = cards.genre.type;
  const promptType1 = cards.prompt1.type;
  const promptType2 = cards.prompt2.type;

  const genreText = cards.genre.text;
  const promptText1 = cards.prompt1.text;
  const promptText2 = cards.prompt2.text;
  const isLegacy = cards.genre.image ? true : false;

  const genrePromptImg = isLegacy ? cards.genre.image.image.url : "";
  const prompt1Img = isLegacy ? cards.prompt1.image.image.url : "";
  const prompt2Img = isLegacy ? cards.prompt2.image.image.url : "";

  const genreState = new CardState(promptTypeGenre);
  genreState.injectCardWithDataFromServer(cards.genre);
  const prompt1State = new CardState(promptType1);
  prompt1State.injectCardWithDataFromServer(cards.prompt1);
  const prompt2State = new CardState(promptType2);
  prompt2State.injectCardWithDataFromServer(cards.prompt2);

  return (
    <div className="story-info-container">
      {isLegacy ? (
        <>
          <div className="genre-types-container center-text">
            <p className="description">{promptTypeGenre}</p>
            <p className="description">{promptType1}</p>
            <p className="description">{promptType2}</p>
          </div>
          <div className="prompt-text-grid read-prompts">
            <p className="description">{genreText}</p>
            <p className="description">&nbsp; &#x2022; &nbsp;</p>
            <p className="description">{promptText1}</p>
            <p className="description">&nbsp; &#x2022; &nbsp;</p>
            <p className="description">{promptText2}</p>
          </div>
          <div className="featured-content-header margin--none">
            <div></div>
            <div className="read-prompts-cards flex--gap-smaller">
              <img
                className="read-prompt-card"
                src={genrePromptImg ? genrePromptImg : BackOfGenreCard}
              />
              <img
                className="read-prompt-card"
                src={prompt1Img ? prompt1Img : BackOfCard}
              />
              <img
                className="read-prompt-card"
                src={prompt2Img ? prompt2Img : BackOfCard}
              />
            </div>
            <div></div>
          </div>
        </>
      ) : (
        <div className="first-stage-container padding--none">
          <div className="battle-cards">
            <BattleCard
              cardState={genreState}
              position={0}
              requestUnlock={null}
              isWorking={false}
              cardIsBeingRedrawn={false}
              forceLockCards={true}
              makeThisAButton={true}
              isTavernCard={uploadedStory?.battleId === "tavern" ? true : false}
            />
            <BattleCard
              cardState={prompt1State}
              position={1}
              requestUnlock={null}
              isWorking={false}
              cardIsBeingRedrawn={false}
              forceLockCards={true}
              makeThisAButton={true}
            />
            <BattleCard
              cardState={prompt2State}
              position={2}
              requestUnlock={null}
              isWorking={false}
              cardIsBeingRedrawn={false}
              forceLockCards={true}
              makeThisAButton={true}
            />
          </div>
        </div>
      )}

      {/* Stuff for genre defintion and prompt reminders */}
      {genreDefinition ? (
        <div className="container flex margin-auto justify-content--center">
          <PromptRules genreDefinition={genreDefinition} />
        </div>
      ) : null}

      {/* Public User means that this is an uploaded story */}
      {publicUser?.name ? (
        <div
          className={
            "flex flex--direction-column margin-top-bigger" +
            " justify-content--center max-width--readable-center"
          }
        >
          <div className="flex align-items--center justify-content--center flex--gap-small">
            <BasicUserInfo publicUserInfo={publicUser} />
            {uploadedStory?.message ? (
              <p className="description pre-wrap">{uploadedStory.message}</p>
            ) : null}
          </div>
          {uploadedStory?.audioLink ? (
            <a
              href={uploadedStory.audioLink}
              target="_blank"
              className="description center-text bold max-width--readable-center footer-link"
            >
              Audio Link: {uploadedStory.audioLink}
            </a>
          ) : null}
          {uploadedStory?.contentWarning ? (
            <p className="description center-text bold max-width--readable-center">
              {hideContentWarnings
                ? "Content warning hidden."
                : "Content warning: " + uploadedStory.contentWarning}
            </p>
          ) : null}
        </div>
      ) : null}

      {/* FLAG STUFF */}
      {uploadedStory &&
      isLoggedIn &&
      uploadedStory.battleId &&
      uploadedStory.houseId &&
      uploadedStory.flagFileName &&
      uploadedStory.houseName ? (
        <div
          className={
            "flex flex--gap-smaller align-items--center " +
            "justify-content--end padding-right"
          }
        >
          <a
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
            href={
              "/debrief?page=houses&battleId=" +
              uploadedStory.battleId +
              "&houseId=" +
              uploadedStory.houseId
            }
          >
            <p className="description--smaller clickable-text--no-margin">
              {uploadedStory.houseName}
            </p>
          </a>
          <a
            href={
              "/debrief?page=houses&battleId=" +
              uploadedStory.battleId +
              "&houseId=" +
              uploadedStory.houseId
            }
          >
            <img
              className="debrief-flag--small"
              src={getFlagImageUrlByFlagFileName(uploadedStory.flagFileName)}
            />
          </a>
        </div>
      ) : null}

      <div className="heading-secondary margin--none read-story-title">
        {storyTitle}
        {publicUser?.name ? (
          <p className="description--large">{"by " + publicUser.name}</p>
        ) : null}
      </div>
      <br />
      {!uploadedStory?.contentWarning && contentWarning ? (
        <p className="description--small center-text italic">
          {hideContentWarnings
            ? "Content warning hidden."
            : "Content warning: " + contentWarning}
        </p>
      ) : null}
    </div>
  );
}

export default StoryInfo;
