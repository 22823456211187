// import css
import "./styles/general.css";
import "./styles/navQueries.css";
import "./styles/queries.css";
import WBLogo from "./img/WritingBattleLogo_800.webp";

// import firebase and react stuff
import { useState, useEffect, useRef } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  auth,
  getServerSidePayPalTokens,
  getPublishableStripeKey,
} from "./config/firebase";

// import components
import Header from "./components/nav/Header";
import Footer from "./components/nav/Footer";
import Home from "./pages/Home";
import PastWinners from "./pages/PastWinners";
import Rules from "./pages/Rules";
import Forum from "./pages/Forum";
import Battle from "./pages/Battle";

// import public functions
import { getRelevantBattles } from "./publicFunctions/publicBattleHandler";
import RegisterOverlay from "./components/register/RegisteryOverlay";
import UserInfo from "./publicFunctions/clientClasses/UserInfo";
import MyProfile from "./publicFunctions/clientClasses/MyProfile";
import {
  getMyPrivateUserInfo,
  getPublicUserInfo,
  logOut,
} from "./publicFunctions/registerHandler";
import MyAccount from "./pages/MyAccount";
import JoinBattle from "./pages/JoinBattle";
import ScrollToTop from "./pages/ScrollToTop";
import {
  getMyGameStates,
  injectUpdatedGameStateIntoCurrentStates,
} from "./publicFunctions/gameHandler";
import Checkout from "./components/join/Checkout";
import CheckoutInfo from "./publicFunctions/clientClasses/CheckoutInfo";
import Story from "./pages/Story";
import { convertAllTimestampsToDatesInArray } from "./publicFunctions/publicHelpers/helpers";
import AdminDuel from "./pages/AdminDuel";
import Profile from "./pages/Profile";
import Debrief from "./pages/Debrief";
import { saveDataToLocalStorageWithExpiry } from "./publicFunctions/localStorageHandler";
import GiftedBattle from "./pages/GiftedBattle";
import ThankYou from "./components/join/ThankYou";
import SEO from "./components/SEO";
import Tarot from "./pages/Tarot";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ConsentBanner from "./components/consent/ConsentBanner";
import { hasConsented, setConsent } from "./publicFunctions/trackingAndConsent";

function App() {
  const [battles, setBattles] = useState([]);
  const [gameStates, setGameStates] = useState(null);
  const [registerPopOver, setRegisterPopOver] = useState("closed");
  const [userInfo, setUserInfo] = useState(new UserInfo());
  const [myProfile, setMyProfile] = useState(new MyProfile());
  const [alreadyJoinedMap, setAlreadyJoinedMap] = useState(new Map());
  const [checkoutInfo, setCheckoutInfo] = useState(new CheckoutInfo());
  const [toggleClosedMenu, setToggleClosedMenu] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const topRef = useRef(null);

  const refreshMyProfile = async (fullRefresh = false) => {
    try {
      if (fullRefresh) {
        await auth.currentUser.reload();
        // Note that this is how you would see custom claims (isActive)
        // const tempInfo = await auth.currentUser.getIdTokenResult(true);
        // console.log(tempInfo);
        const tempUserInfo = userInfo.clone();
        tempUserInfo.displayName = auth.currentUser.displayName;
        setUserInfo(tempUserInfo);
      }
      const privateInfo = await getMyPrivateUserInfo(userInfo.userId);
      const publicInfo = await getPublicUserInfo(userInfo.userId);
      try {
        saveDataToLocalStorageWithExpiry(userInfo.userId, publicInfo);
      } catch (err) {
        console.log(err);
      }
      const emptyProfile = new MyProfile();
      emptyProfile.setProfile(privateInfo, publicInfo);
      setMyProfile(emptyProfile);
      const myGameStates = await getMyGameStates();
      if (myGameStates && myGameStates.length > 0) {
        setGameStateWrapper(myGameStates);
      }

      return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (userInfo.isInitialized() && !myProfile.isInitialized()) {
      refreshMyProfile();
    } else {
      // logged out
      setGameStateWrapper(null);
      setAlreadyJoinedMap(new Map());
      setMyProfile(new MyProfile());
    }
    setToggleClosedMenu(!toggleClosedMenu);
  }, [userInfo]);

  useEffect(() => {
    getRelevantBattles().then((result) => {
      setBattles(result);
    });

    auth.onAuthStateChanged((user) => {
      setRegisterPopOver("closed");
      if (user) {
        const emptyUserInfo = new UserInfo();
        // this also means that detailed info should also be loaded
        // info is empty so load it
        if (user.displayName) emptyUserInfo.displayName = user.displayName;
        // userInfo.isActive = TODO: get if the user is active
        if (user.photoURL) emptyUserInfo.photoURL = user.photoURL;
        emptyUserInfo.pending = false;
        emptyUserInfo.email = user.email;
        emptyUserInfo.userId = user.uid;

        setUserInfo(emptyUserInfo);
      } else {
        // logged out
        const definitelyNotLoggedIn = new UserInfo();
        definitelyNotLoggedIn.pending = false;
        setUserInfo(definitelyNotLoggedIn);
      }

      // check if the user has already given consent (or not)
      if (!hasConsented()) {
        setShowConsent(true);
      } else {
        allConsentGranted();
      }
    });
  }, []); // do this the first time this component is mounted

  useEffect(() => {
    if (gameStates && gameStates.length) {
      const tempMap = new Map();
      for (const gameState of gameStates) {
        tempMap.set(gameState.battleId, true);
      }
      setAlreadyJoinedMap(tempMap);
    } else if (!gameStates?.length) {
      setAlreadyJoinedMap(new Map());
    }
  }, [gameStates]);

  const closeRegisterOverlay = () => {
    setRegisterPopOver("closed");
  };

  const openRegisterOverlay = (page) => {
    setRegisterPopOver(page);
  };

  const logOutHandler = async () => {
    try {
      await logOut();
    } catch (err) {
      console.log(err.message);
    }
  };

  const switchToCheckout = async (
    selectedBattles,
    totalInCurrency,
    creditToUse,
    currency,
    thisIsAGift,
    isStripe = false
  ) => {
    if (selectedBattles.length <= 0)
      throw {
        name: "WBError",
        message: "Please select at least one Battle to join.",
      };

    const tempCheckoutInfo = new CheckoutInfo();
    tempCheckoutInfo.selectedBattles = selectedBattles;
    tempCheckoutInfo.currency = currency;
    tempCheckoutInfo.amount = totalInCurrency;
    tempCheckoutInfo.thisIsAGift = thisIsAGift;
    if (isStripe) {
      const { data } = await getPublishableStripeKey();
      tempCheckoutInfo.isStripe = true;
      tempCheckoutInfo.publishableStripeKey = data.publishableKey;
    } else {
      const payPalTokens = await getServerSidePayPalTokens();
      tempCheckoutInfo.clientId = payPalTokens.data.clientId;
      tempCheckoutInfo.clientToken = payPalTokens.data.clientToken;
    }
    if (creditToUse) tempCheckoutInfo.creditToUse = creditToUse;
    setCheckoutInfo(tempCheckoutInfo);
  };

  const closeCheckout = (finishedTransaction, success = false) => {
    const tempCheckout = new CheckoutInfo();
    if (finishedTransaction) {
      tempCheckout.finishedCheckout = true;
      tempCheckout.successfulCheckout = success;
      refreshMyProfile(false);
    }
    setCheckoutInfo(tempCheckout);
  };

  const refreshGameState = async (updatedGameState) => {
    if (updatedGameState && gameStates) {
      const { success, newGameStatesArray } =
        injectUpdatedGameStateIntoCurrentStates(gameStates, updatedGameState);

      if (success && newGameStatesArray) {
        setGameStateWrapper(newGameStatesArray);
        return;
      }
    }
    const myGameStates = await getMyGameStates();
    if (myGameStates && myGameStates.length > 0) {
      setGameStateWrapper(myGameStates);
    } else {
      setGameStates([]);
    }
  };

  function allConsentGranted() {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    } else {
      console.log("does not exist");
    }
  }

  const giveConsentHandler = () => {
    allConsentGranted();
    setConsent(true);
    setShowConsent(false);
  };

  const closeConsentHandler = () => {
    setShowConsent(false);
  };

  function setGameStateWrapper(gameStateArray) {
    if (gameStateArray?.length) {
      gameStateArray.forEach((gs) => {
        if (gs?.duels?.length) {
          convertAllTimestampsToDatesInArray(gs.duels);
        }
      });
    }
    setGameStates(gameStateArray);
  }

  const showHeaderAndFooter =
    !window.location.pathname.includes("/story") ||
    window.location.pathname.includes("/upload") ||
    window.location.pathname.includes("/debrief") ||
    window.location.pathname.includes("/tavern");

  let initialPathName = "";
  if (window.location.pathname.includes("/debrief")) {
    initialPathName = "debrief";
  } else if (window.location.pathname.includes("/join-battle")) {
    initialPathName = "join-battle";
  } else if (window.location.pathname.includes("/past-winners")) {
    initialPathName = "past-winners";
  } else if (window.location.pathname.includes("/rules")) {
    initialPathName = "rules";
  } else if (window.location.pathname.includes("/forum")) {
    initialPathName = "forum";
  } else if (window.location.pathname.includes("/battle")) {
    initialPathName = "battle";
  } else if (window.location.pathname.includes("/tavern")) {
    initialPathName = "tavern";
  }

  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <ScrollToTop />
        <RegisterOverlay
          closeHandler={closeRegisterOverlay}
          goToPage={registerPopOver}
          userInfo={userInfo}
        />
        {checkoutInfo.showCheckout() ? (
          <Checkout checkoutInfo={checkoutInfo} closeCheckout={closeCheckout} />
        ) : null}
        {showHeaderAndFooter ? (
          <Header
            openLogInHandler={openRegisterOverlay}
            userInfo={userInfo}
            logOutHandler={logOutHandler}
            myProfile={myProfile}
            toggleClosedMenu={toggleClosedMenu}
            initialPathName={initialPathName}
            topRef={topRef}
          />
        ) : null}
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Home
                battles={battles}
                openLogInHandler={openRegisterOverlay}
                userInfo={userInfo}
                alreadyJoinedMap={alreadyJoinedMap}
              />
            }
          />
          <Route
            path="/past-winners"
            element={
              <PastWinners
                battles={battles}
                openLogInHandler={openRegisterOverlay}
                userInfo={userInfo}
                alreadyJoinedMap={alreadyJoinedMap}
              />
            }
          />
          <Route
            path="/story/:battleId/:storyId/:duelId"
            element={
              <Story
                userInfo={userInfo}
                openLogInHandler={openRegisterOverlay}
                myProfile={myProfile}
              />
            }
          />
          <Route
            path="/story/:battleId/:storyId"
            element={
              <Story
                userInfo={userInfo}
                openLogInHandler={openRegisterOverlay}
                myProfile={myProfile}
                alreadyJoinedMap={alreadyJoinedMap}
                battles={battles}
              />
            }
          />
          <Route
            path="/rules/:battleId"
            element={<Rules battles={battles} />}
          />
          <Route
            path="/admin-duel"
            element={<AdminDuel battles={battles} userInfo={userInfo} />}
          />
          <Route
            path="/my-account"
            element={
              <MyAccount
                openLogInHandler={openRegisterOverlay}
                userInfo={userInfo}
                myProfile={myProfile}
                refreshMyProfile={refreshMyProfile}
              />
            }
          />
          <Route
            path="/join-battle"
            element={
              <JoinBattle
                battles={battles}
                alreadyJoinedMap={alreadyJoinedMap}
                switchToCheckout={switchToCheckout}
                credit={myProfile.wallet.credit}
                thankYouState={checkoutInfo}
                refreshMyProfile={refreshMyProfile}
                closeCheckout={closeCheckout}
                userInfo={userInfo}
                openRegisterOverlay={openRegisterOverlay}
              />
            }
          />
          <Route
            path="/rules"
            element={
              <Rules
                battles={battles}
                openLogInHandler={openRegisterOverlay}
                userInfo={userInfo}
                alreadyJoinedMap={alreadyJoinedMap}
              />
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/tarot"
            element={
              <Tarot
                battles={battles}
                openLogInHandler={openRegisterOverlay}
                userInfo={userInfo}
                alreadyJoinedMap={alreadyJoinedMap}
              />
            }
          />
          <Route
            path="/forum"
            element={
              <Forum
                userInfo={userInfo}
                openLogInHandler={openRegisterOverlay}
                battles={battles}
                gameStates={gameStates}
                refreshGameState={refreshGameState}
              />
            }
          />
          <Route
            path="/battle"
            element={
              <Battle
                battles={battles}
                openLogInHandler={openRegisterOverlay}
                userInfo={userInfo}
                gameStates={gameStates}
                alreadyJoinedMap={alreadyJoinedMap}
                refreshGameState={refreshGameState}
              />
            }
          />
          <Route
            path="/profile/:publicId"
            element={
              <Profile
                userInfo={userInfo}
                myProfile={myProfile}
                refreshMyProfile={refreshMyProfile}
              />
            }
          />
          {/* <Route path="/ceasefire/:ceasefireKey" element={<Ceasefire />} /> */}
          <Route
            path="/gifted-battle/:giftCode"
            element={
              <GiftedBattle
                userInfo={userInfo}
                openLogInHandler={openRegisterOverlay}
              />
            }
          />
          <Route
            path="/receive-digital-deck/:giftCode"
            element={
              <GiftedBattle
                userInfo={userInfo}
                openLogInHandler={openRegisterOverlay}
                typeOfTicket="Digital Deck"
              />
            }
          />
          <Route
            path="/debrief"
            element={
              <Debrief
                key={"debrief_page"}
                battles={battles}
                userInfo={userInfo}
                myProfile={myProfile}
                openLogInHandler={openRegisterOverlay}
                alreadyJoinedMap={alreadyJoinedMap}
                isTavern={false}
              />
            }
          />
          <Route
            path="/tavern"
            element={
              <Debrief
                key={"tavern_page"}
                battles={battles}
                userInfo={userInfo}
                myProfile={myProfile}
                openLogInHandler={openRegisterOverlay}
                alreadyJoinedMap={alreadyJoinedMap}
                isTavern={true}
              />
            }
          />
          <Route
            path="/thank-you"
            element={
              <div className="hero-background">
                <SEO
                  title="Thank you | Writing Battle"
                  name="Writing Battle"
                  type="website"
                />
                <div>
                  <div className="container heading--join-battle">
                    <div className="flex flex--gap-normal flex--direction-column--mobile">
                      <h1 className="heading-primary"> Thank you!</h1>
                    </div>
                  </div>
                  <div className="join-battle">
                    <>
                      <ThankYou
                        wasSuccessful={true}
                        resetThankYou={() => {}}
                        gifted={false}
                      />
                      <img className="thank-you-img" src={WBLogo}></img>
                    </>
                  </div>
                </div>
              </div>
            }
          />
        </Routes>
        {showHeaderAndFooter ? (
          <Footer
            openLogInHandler={openRegisterOverlay}
            userInfo={userInfo}
            topRef={topRef}
          />
        ) : null}
        {showConsent ? (
          <ConsentBanner
            giveConsentHandler={giveConsentHandler}
            closeConsentHandler={closeConsentHandler}
          />
        ) : null}
      </Router>
    </HelmetProvider>
  );
}

export default App;
