import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { flashOutline, shirtOutline } from "ionicons/icons";
import SocialLinks from "../SocialLinks";
import MobileNav from "./MobileNav";
import supportingLogo from "../../img/logos/writingBattleLogoSupporting.webp";
import {
  getAttention,
  getMyNotifications,
} from "../../publicFunctions/publicProfileHandler";
import Notifications from "./Notifications";

function Header({
  openLogInHandler,
  userInfo,
  logOutHandler,
  myProfile,
  toggleClosedMenu,
  initialPathName,
  topRef,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const [selectedPage, setSelectedPage] = useState(
    initialPathName ? initialPathName : ""
  );
  const [attention, setAttention] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState("Unknown");
  const [notificationsObject, setNotificationsObject] = useState(null);
  const [newNotifications, setNewNotifications] = useState(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!attention) {
      getAttention()
        .then((attentionMessage) => {
          if (attentionMessage) {
            setAttention(attentionMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    setIsClicked(false);
  }, [toggleClosedMenu]);

  // we only want to do this once
  useEffect(() => {
    if (isLoggedIn === true) {
      if (userInfo?.userId) {
        getMyNotifications(userInfo.userId)
          .then((notif) => {
            if (notif) {
              setNewNotifications(notif.newNotifications ? true : false);
              setNotificationsObject(
                notif?.notificationsList?.length ? notif : null
              );
            } else {
              setNotificationsObject(null);
            }
          })
          .catch((err) => {
            console.log(err);
            setNotificationsObject(null);
          });
      } else {
        setNotificationsObject(null);
      }
    } else if (isLoggedIn === false) {
      setNotificationsObject(null);
    }
  }, [isLoggedIn]);

  // when we are logged in, we want to check for notifications
  // if not logged in, then show a button to login
  useEffect(() => {
    if (userInfo?.isLoggedIn()) {
      setIsLoggedIn(true);
    } else if (
      userInfo &&
      !userInfo.isStillCheckingLogin() &&
      !userInfo.isLoggedIn()
    ) {
      setIsLoggedIn(false);
    }
  }, [userInfo]);

  const handleMobileNavClick = () => {
    setIsClicked(!isClicked);
  };

  const closeMenu = () => {
    setIsClicked(false);
  };

  const accountSiteRequested = (event) => {
    if (event?.target?.value) {
      const value = event.target.value;
      if (value === "My Account") {
        closeMenu();
        navigate("/my-account");
      } else if (value === "My Profile" && myProfile?.publicProfile?.pageName) {
        closeMenu();
        navigate("/profile/" + myProfile.publicProfile.pageName);
      } else if (value === "Log Out") {
        logOutHandler();
      }
    }
  };

  return (
    <>
      {attention ? (
        <div className="red-background padding--small">
          <p className="description bold center-text white">{attention}</p>
        </div>
      ) : null}
      <header
        ref={topRef}
        id="top"
        className={isClicked ? "header nav-open" : "header"}
      >
        <NavLink
          onClick={() => {
            closeMenu();
            setSelectedPage("");
          }}
          to="/"
        >
          <img
            className="logo"
            src={supportingLogo}
            alt="Writing Battle logo"
          />
        </NavLink>
        <nav className="main-nav">
          <div className="mobile-specific-list">
            <NavLink
              className="mobile-nav-logo mobile-logo-flex"
              onClick={() => {
                closeMenu();
                setSelectedPage("");
              }}
              to="/"
            >
              <img
                className="mobile-logo-smaller"
                src={supportingLogo}
                alt="Writing Battle logo nav"
              />
            </NavLink>
            <ul className="main-nav-list">
              <li>
                <NavLink
                  onClick={(event) => {
                    if (pathname.includes("join-battle")) {
                      event.preventDefault();
                    } else {
                      closeMenu();
                      setSelectedPage("join-battle");
                    }
                  }}
                  className={
                    selectedPage === "join-battle"
                      ? "main-nav-link main-nav-link--selected"
                      : "main-nav-link"
                  }
                  to="/join-battle"
                >
                  <div className="special-link">
                    Join
                    <IonIcon className="social-icon" icon={flashOutline} />
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    closeMenu();
                  }}
                  className="main-nav-link"
                  to="https://www.writingbattle.shop"
                  target="_blank"
                >
                  <div className="special-link">
                    Merch
                    <IonIcon className="social-icon" icon={shirtOutline} />
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={(event) => {
                    if (pathname.includes("past-winners")) {
                      event.preventDefault();
                    } else {
                      closeMenu();
                      setSelectedPage("past-winners");
                    }
                  }}
                  className={
                    selectedPage === "past-winners"
                      ? "main-nav-link main-nav-link--selected"
                      : "main-nav-link"
                  }
                  to="/past-winners"
                >
                  Past Winners
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    closeMenu();
                    setSelectedPage("rules");
                  }}
                  className={
                    selectedPage === "rules"
                      ? "main-nav-link main-nav-link--selected"
                      : "main-nav-link"
                  }
                  to="/rules"
                >
                  Rules
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={(event) => {
                    if (
                      pathname.includes("forum") &&
                      !search.includes("page=topic&")
                    ) {
                      event.preventDefault();
                    } else {
                      closeMenu();
                      setSelectedPage("forum");
                    }
                  }}
                  className={
                    selectedPage === "forum"
                      ? "main-nav-link main-nav-link--selected"
                      : "main-nav-link"
                  }
                  to={
                    search.includes("page=topic&")
                      ? "/forum?page=topics"
                      : "/forum"
                  }
                >
                  Forum
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={(event) => {
                    if (
                      pathname.includes("tavern") &&
                      !pathname.includes("story")
                    ) {
                      event.preventDefault();
                    } else {
                      closeMenu();
                      setSelectedPage("tavern");
                    }
                  }}
                  className={
                    selectedPage === "tavern"
                      ? "main-nav-link main-nav-link--selected"
                      : "main-nav-link"
                  }
                  to="/tavern"
                >
                  Tavern
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={(event) => {
                    if (
                      pathname.includes("debrief") &&
                      !pathname.includes("story")
                    ) {
                      event.preventDefault();
                    } else {
                      closeMenu();
                      setSelectedPage("debrief");
                    }
                  }}
                  className={
                    selectedPage === "debrief"
                      ? "main-nav-link main-nav-link--selected"
                      : "main-nav-link"
                  }
                  to="/debrief"
                >
                  Debrief
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => {
                    closeMenu();
                    setSelectedPage("battle");
                  }}
                  className={
                    selectedPage === "battle"
                      ? "main-nav-link main-nav-link--selected"
                      : "main-nav-link"
                  }
                  to="/battle"
                >
                  Battle
                </NavLink>
              </li>
              <li>
                <div className="flex flex--gap-small align-items--center">
                  <Notifications
                    userInfo={userInfo}
                    hideOnMobile={true}
                    isLoggedIn={isLoggedIn}
                    notificationsObject={notificationsObject}
                    newNotifications={newNotifications}
                    setNewNotifications={setNewNotifications}
                  />
                  {userInfo && userInfo.isInitialized() ? (
                    <select
                      className="select--myAccount"
                      id="main-nav-account-pages"
                      value={"Hello, " + userInfo.displayName}
                      onChange={(event) => {
                        accountSiteRequested(event);
                        setSelectedPage("");
                      }}
                    >
                      <option value={"Hello, " + userInfo.displayName}>
                        {"Hello, " + userInfo.displayName}
                      </option>
                      <option value="My Account">My Account</option>
                      <option value="My Profile">My Profile</option>
                      <option value="Log Out">Log Out</option>
                    </select>
                  ) : (
                    <button
                      onClick={() => openLogInHandler("login")}
                      className="login-btn"
                    >
                      Register / Log In
                    </button>
                  )}
                </div>
              </li>
            </ul>
            <SocialLinks makeLarge={true} />
          </div>
        </nav>
        <div className="mobile-notifications--position">
          <Notifications
            userInfo={userInfo}
            hideOnMobile={false}
            isLoggedIn={isLoggedIn}
            notificationsObject={notificationsObject}
            newNotifications={newNotifications}
            setNewNotifications={setNewNotifications}
          />
          <MobileNav onClick={handleMobileNavClick} />
        </div>
      </header>
    </>
  );
}

export default Header;
