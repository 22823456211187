import { getCardImageUrlByCard } from "../publicHelpers/imageUrlBuilder";

class CardState {
  constructor(type) {
    this.type = type;
    this.text = "";
    this.isLocked = true;
    this.isHardLocked = true;
    this.image = null;
    this.definition = ""; // used for Genre
    this.isLegacy = false;
    this.tavernCard = false;
  }

  setLocked(locked) {
    this.isLocked = locked;
  }

  setHardLocked(locked) {
    this.isHardLocked = locked;
    // if we're hard locked, then we're also locked
    if (locked) this.isLocked = true;
  }

  clone() {
    const cloned = new CardState();
    cloned.type = this.type;
    cloned.text = this.text;
    cloned.isLocked = this.isLocked;
    cloned.isHardLocked = this.isHardLocked;
    cloned.image = this.image;
    cloned.definition = this.definition;
    cloned.isLegacy = this.isLegacy;
    cloned.tavernCard = this.tavernCard;
    return cloned;
  }

  isGenre() {
    return this.type === "Genre" ? true : false;
  }

  isTavernCard() {
    return this.tavernCard ? true : false;
  }

  injectCardWithDataFromServer(serverCard) {
    this.text = serverCard.text;
    this.image = getCardImageUrlByCard(serverCard);
    this.isLegacy = serverCard.image ? true : false;
    this.type = serverCard.type;
    this.tavernCard = serverCard.tavernCard ? true : false;
  }
}

export default CardState;
