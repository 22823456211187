import QUEEN_LANDSCAPE_AUTUMN from "../../img/home/queen_landscape_autumn.webp";
import QUEEN_LANDSCAPE_WINTER from "../../img/home/queen_landscape_winter.webp";
import QUEEN_LANDSCAPE_SPRING from "../../img/home/queen_landscape_spring.webp";
import QUEEN_LANDSCAPE_SUMMER from "../../img/home/queen_landscape_summer.webp";
import HALF_QUEEN_LANDSCAPE_WINTER from "../../img/halfQueens/WinterLandscape.webp";
import HALF_QUEEN_LANDSCAPE_SPRING from "../../img/halfQueens/SpringLandscape.webp";
import HALF_QUEEN_LANDSCAPE_SUMMER from "../../img/halfQueens/SummerLandscape.webp";
import HALF_QUEEN_LANDSCAPE_AUTUMN from "../../img/halfQueens/AutumnLandscape.webp";
import KING_COINS from "../../img/home/King_Coin.webp";
import KING_CUPS from "../../img/home/King_Cups.webp";
import KING_SWORDS from "../../img/home/King_Sword.webp";
import KING_WANDS from "../../img/home/King_Wands.webp";
import PAGE_COINS from "../../img/home/Page_Coin.webp";
import PAGE_CUPS from "../../img/home/Page_Cups.webp";
import PAGE_SWORDS from "../../img/home/Page_Sword.webp";
import PAGE_WANDS from "../../img/home/Page_Wands.webp";
import QUEEN_COINS from "../../img/home/Queen_Coin.webp";
import QUEEN_CUPS from "../../img/home/Queen_Cups.webp";
import QUEEN_SWORDS from "../../img/home/Queen_Sword.webp";
import QUEEN_WANDS from "../../img/home/Queen_Wands.webp";

export const WINTER = "WINTER";
export const SPRING = "SPRING";
export const SUMMER = "SUMMER";
export const AUTUMN = "AUTUMN";

// colors
const AUTUMN_COLOR = "#c37959";
const AUTUMN_COLOR_DARKER = "#9c6147";
const AUTUMN_COLOR_DARKEST = "#754935";

const WINTER_COLOR = "#80959d";
const WINTER_COLOR_DARKER = "#66777e";
const WINTER_COLOR_DARKEST = "#4d595e";

const SPRING_COLOR = "#d03f37";
const SPRING_COLOR_DARKER = "#a6322c";
const SPRING_COLOR_DARKEST = "#7d2621";

const SUMMER_COLOR = "#8a975f";
const SUMMER_COLOR_DARKER = "#6e794c";
const SUMMER_COLOR_DARKEST = "#535b39";

export function getSeason(date) {
  if (!date) {
    date = new Date();
  }
  const month = date.getMonth();
  if (month === 11 || month <= 1) {
    return WINTER;
  } else if (month <= 4) {
    return SPRING;
  } else if (month <= 7) {
    return SUMMER;
  } else {
    return AUTUMN;
  }
}

export function getSeasonByBattleId(battleId) {
  if (!battleId) {
    return AUTUMN;
  }
  if (battleId.includes("feb")) {
    return WINTER;
  } else if (battleId.includes("may")) {
    return SPRING;
  } else if (battleId.includes("aug")) {
    return SUMMER;
  } else {
    return AUTUMN;
  }
}

export function getPageForSeason(season) {
  if (season === AUTUMN) {
    return PAGE_WANDS;
  } else if (season === WINTER) {
    return PAGE_SWORDS;
  } else if (season === SPRING) {
    return PAGE_COINS;
  } else if (season === SUMMER) {
    return PAGE_CUPS;
  } else {
    // default
    return PAGE_WANDS;
  }
}

export function getQueenForSeason(season) {
  if (season === AUTUMN) {
    return QUEEN_WANDS;
  } else if (season === WINTER) {
    return QUEEN_SWORDS;
  } else if (season === SPRING) {
    return QUEEN_COINS;
  } else if (season === SUMMER) {
    return QUEEN_CUPS;
  } else {
    // default
    return QUEEN_WANDS;
  }
}

export function getHalfQueenLandscapeForSeason(season) {
  if (season === AUTUMN) {
    return HALF_QUEEN_LANDSCAPE_AUTUMN;
  } else if (season === WINTER) {
    return HALF_QUEEN_LANDSCAPE_WINTER;
  } else if (season === SPRING) {
    return HALF_QUEEN_LANDSCAPE_SPRING;
  } else if (season === SUMMER) {
    return HALF_QUEEN_LANDSCAPE_SUMMER;
  } else {
    // default
    return HALF_QUEEN_LANDSCAPE_AUTUMN;
  }
}

export function getKingForSeason(season) {
  if (season === AUTUMN) {
    return KING_WANDS;
  } else if (season === WINTER) {
    return KING_SWORDS;
  } else if (season === SPRING) {
    return KING_COINS;
  } else if (season === SUMMER) {
    return KING_CUPS;
  } else {
    // default
    return KING_WANDS;
  }
}

export function getQueenLandscape(season) {
  if (season === AUTUMN) {
    return QUEEN_LANDSCAPE_AUTUMN;
  } else if (season === WINTER) {
    return QUEEN_LANDSCAPE_WINTER;
  } else if (season === SPRING) {
    return QUEEN_LANDSCAPE_SPRING;
  } else if (season === SUMMER) {
    return QUEEN_LANDSCAPE_SUMMER;
  } else {
    // default
    return QUEEN_LANDSCAPE_AUTUMN;
  }
}

export function getLandscapeFontColor(season) {
  if (season === AUTUMN) {
    return AUTUMN_COLOR;
  } else if (season === WINTER) {
    return WINTER_COLOR;
  } else if (season === SPRING) {
    return SPRING_COLOR;
  } else if (season === SUMMER) {
    return SUMMER_COLOR;
  } else {
    // default
    return AUTUMN_COLOR;
  }
}

export function getDarkerFontColor(color) {
  if (color === AUTUMN_COLOR) {
    return AUTUMN_COLOR_DARKER;
  } else if (color === WINTER_COLOR) {
    return WINTER_COLOR_DARKER;
  } else if (color === SPRING_COLOR) {
    return SPRING_COLOR_DARKER;
  } else if (color === SUMMER_COLOR) {
    return SUMMER_COLOR_DARKER;
  } else {
    return AUTUMN_COLOR_DARKER;
  }
}

export function getDarkestFontColor(color) {
  if (color === AUTUMN_COLOR) {
    return AUTUMN_COLOR_DARKEST;
  } else if (color === WINTER_COLOR) {
    return WINTER_COLOR_DARKEST;
  } else if (color === SPRING_COLOR) {
    return SPRING_COLOR_DARKEST;
  } else if (color === SUMMER_COLOR) {
    return SUMMER_COLOR_DARKEST;
  } else {
    return AUTUMN_COLOR_DARKEST;
  }
}
