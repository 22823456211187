import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";
import BasicPageNavControls from "../../publicFunctions/clientClasses/BasicPageNavControls";
import BasicPageNav from "../BasicPageNav";
import LoadingSection from "../LoadingSection";
import { Timestamp } from "firebase/firestore";
import { fetchReplies } from "../../publicFunctions/forumHandler";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import {
  DEFAULT_FORUM_LIST_SIZE,
  mapStalePagination,
  pageScrollSettings,
} from "../../publicFunctions/publicHelpers/helpers";
import Reply from "./Reply";

function ReplyList({
  myUserId,
  topicId,
  searchParams,
  setSearchParams,
  forceRefresh,
  stalePaginationArray,
  handleWillNeedToSeek,
  handleGatherSuggestedUserIds,
  handleReplyToMessage,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const topRef = useRef(null);
  const commentRef = useRef(null);

  const [fullReset, setFullReset] = useState(false);
  const [forceRefreshNav, setForceRefreshNav] = useState(false);
  const [noMoreTopics, setNoMoreTopics] = useState(false);
  const [renderedTopics, setRenderedTopics] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [pageNavControls, setPageNavControls] = useState(
    new BasicPageNavControls(
      "/forum?page=topic&topicId=" + topicId,
      "repliesNum",
      "repliesIndex",
      null,
      mapStalePagination(stalePaginationArray)
    )
  );
  const [doThisOnlyOnce, setDoThisOnlyOnce] = useState("");
  const [savedNextIndex, setSavedNextIndex] = useState("");

  useEffect(() => {
    if (searchParams.get("page") === "topic") {
      const split = forceRefresh.split("_");
      const key = split[0];
      const onlyOnce = split.length > 1 ? split[1] : "";
      const firstTime = onlyOnce !== doThisOnlyOnce ? true : false;
      if (firstTime) {
        setDoThisOnlyOnce(onlyOnce);
      }

      if (firstTime && key === "seekLastPage") {
        const goToLastPage = true;
        loadReplies(goToLastPage);
      } else if (searchParams.has("searchTime")) {
        const goToLastPage = false;
        loadReplies(goToLastPage, searchParams.get("searchTime"));
      } else {
        const goToLastPage = false;
        loadReplies(goToLastPage);
      }
    }
  }, [searchParams, forceRefresh]);

  useEffect(() => {
    if (pageNavControls && stalePaginationArray?.length > 1) {
      setPageNavControls(
        pageNavControls.mapStalePaginationThenClone(stalePaginationArray)
      );
    }
  }, [stalePaginationArray]);

  async function loadReplies(goToLastPage = false, searchTime = null) {
    setIsWorking(true);
    if (topRef && searchParams?.get("repliesIndex")) {
      topRef.current.scrollIntoView(pageScrollSettings);
    }
    try {
      let lastCreatedDate = null;
      let currentIndex = searchParams.get("repliesIndex");

      if (goToLastPage) {
        setForceRefreshNav(!forceRefreshNav);
        const pagination = pageNavControls.pagination;
        let lastPageInt = pagination.size;
        // if we need to jump to the next page if this is full
        if (savedNextIndex) {
          lastPageInt = pagination.size + 1;
          pagination.set(lastPageInt.toString(10), savedNextIndex);
          currentIndex = savedNextIndex;
        } else {
          currentIndex = pagination.get(lastPageInt.toString(10));
        }
        const params = searchParams;
        params.set("repliesNum", lastPageInt.toString(10));
        params.set("repliesIndex", currentIndex);
        setSearchParams(params);
        return;
      } else if (searchTime && stalePaginationArray?.length) {
        setForceRefreshNav(!forceRefreshNav);
        let pageToGoTo = stalePaginationArray[0].page;
        let indexToGoTo = "none";
        const searchTimeInt = parseInt(searchTime);
        for (let i = stalePaginationArray.length - 1; i >= 1; i--) {
          const paginationObj = stalePaginationArray[i];
          const indexToCheck = parseInt(paginationObj.index);
          if (!isNaN(indexToCheck) && searchTimeInt > indexToCheck) {
            pageToGoTo = paginationObj.page;
            indexToGoTo = paginationObj.index;
            break;
          }
        }
        const params = searchParams;
        params.set("repliesNum", pageToGoTo);
        params.set("repliesIndex", indexToGoTo);
        params.delete("searchTime");
        setSearchParams(params);
        return;
        // we need to search the list here and find the page to jump to
      } else if (currentIndex && currentIndex !== "none") {
        lastCreatedDate = Timestamp.fromMillis(parseInt(currentIndex));
      }

      const replies = await fetchReplies(topicId, lastCreatedDate);

      if (replies.length === DEFAULT_FORUM_LIST_SIZE + 1) {
        const pagination = pageNavControls.pagination;
        const createdDate = replies[DEFAULT_FORUM_LIST_SIZE - 1].createdDate;
        const milliseconds = Math.floor(
          createdDate.seconds * 1000 +
            Math.ceil(createdDate.nanoseconds / 1000000)
        );
        const nextIndex = milliseconds.toString(10);

        let numString;
        if (goToLastPage) {
          numString = pagination.size.toString(10);
        } else {
          numString = searchParams.get("repliesNum");
        }

        if (currentIndex && currentIndex !== "none" && numString) {
          pagination.set(numString, currentIndex);
        }

        // since we're here, we know that there IS more pagination.
        if (pagination?.size && pagination.size === parseInt(numString)) {
          // add another page
          pagination.set((pagination.size + 1).toString(10), nextIndex);
          setForceRefreshNav(!forceRefreshNav);
        }

        setPageNavControls(
          new BasicPageNavControls(
            pageNavControls.defaultNavigateString,
            pageNavControls.numParamName,
            pageNavControls.indexParamName,
            nextIndex,
            pagination
          )
        );
        setNoMoreTopics(false);
      } else if (replies.length <= DEFAULT_FORUM_LIST_SIZE) {
        setNoMoreTopics(true);
      }

      // SPECIAL CASE! We want to store this value
      // just in case this person posts and we need to
      // jump to the next page
      if (replies.length === DEFAULT_FORUM_LIST_SIZE) {
        const createdDate = replies[DEFAULT_FORUM_LIST_SIZE - 1].createdDate;
        const milliseconds = Math.floor(
          createdDate.seconds * 1000 +
            Math.ceil(createdDate.nanoseconds / 1000000)
        );
        const nextIndex = milliseconds.toString(10);
        setSavedNextIndex(nextIndex);
      } else {
        setSavedNextIndex(null);
      }

      // will we need to seek?
      if (replies.length >= DEFAULT_FORUM_LIST_SIZE) {
        handleWillNeedToSeek(true);
      } else {
        handleWillNeedToSeek(false);
      }

      if (replies?.length) {
        const uniqueUserIdMap = new Map();
        const tempRender = [];
        for (
          let i = 0;
          i < replies.length && i < DEFAULT_FORUM_LIST_SIZE;
          i++
        ) {
          const reply = replies[i];

          // get public profile for this user
          const publicUser = await getPublicProfileByUserId(reply.userId);
          uniqueUserIdMap.set(reply.userId, true);
          tempRender.push(
            <div key={reply.messageId}>
              <Reply
                canEdit={reply.userId === myUserId}
                reply={reply}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                publicUser={publicUser}
                handleReplyToMessage={handleReplyToMessage}
              />
            </div>
          );
        }
        handleGatherSuggestedUserIds(Array.from(uniqueUserIdMap.keys()));
        setRenderedTopics(tempRender);
      } else {
        setRenderedTopics([]);
      }
    } catch (err) {
      console.log(err);
      setNoMoreTopics(true);
    } finally {
      setIsWorking(false);
    }
  }

  return (
    <div>
      <button
        onClick={() => {
          if (commentRef?.current) {
            commentRef.current.scrollIntoView();
          }
        }}
        className="btn btn--register min-width--even-larger align-self--end"
      >
        <div className="flex justify-content--center align-items--center flex--gap-smaller">
          Comment
          <IonIcon className="social-icon" icon={arrowDownOutline} />
        </div>
      </button>
      <div ref={topRef} className="simple-divider"></div>

      {/* TOP NAV */}
      {renderedTopics?.length || pageNavControls?.pagination ? (
        <BasicPageNav
          pageNum={
            searchParams.has("repliesNum")
              ? parseInt(searchParams.get("repliesNum"))
              : 1
          }
          noMorePages={noMoreTopics}
          incrementPageHandler={() => {
            pageNavControls.incrementPageHandler(searchParams, setSearchParams);
          }}
          decrementPageHandler={() => {
            pageNavControls.decrementPageHandler(
              location,
              navigate,
              searchParams,
              setSearchParams
            );
          }}
          goToPageHandler={(page, index) => {
            pageNavControls.goToPage(
              page,
              index,
              searchParams,
              setSearchParams
            );
          }}
          isWorking={isWorking}
          pagination={pageNavControls.pagination}
          showPageDropdown={true}
          forceRefreshNav={forceRefreshNav}
        />
      ) : null}

      {/* PAYLOAD */}
      <div key={fullReset} className="topics-list">
        {!isWorking ? renderedTopics : <LoadingSection />}
      </div>

      {/* BOTTOM NAV */}
      {renderedTopics?.length || pageNavControls?.pagination ? (
        <BasicPageNav
          pageNum={
            searchParams.has("repliesNum")
              ? parseInt(searchParams.get("repliesNum"))
              : 1
          }
          noMorePages={noMoreTopics}
          incrementPageHandler={() => {
            pageNavControls.incrementPageHandler(searchParams, setSearchParams);
          }}
          decrementPageHandler={() => {
            pageNavControls.decrementPageHandler(
              location,
              navigate,
              searchParams,
              setSearchParams
            );
          }}
          goToPageHandler={(page, index) => {
            pageNavControls.goToPage(
              page,
              index,
              searchParams,
              setSearchParams
            );
          }}
          isWorking={isWorking}
          pagination={pageNavControls.pagination}
          showPageDropdown={true}
          forceRefreshNav={forceRefreshNav}
        />
      ) : null}
      <div ref={commentRef} className="simple-divider"></div>
    </div>
  );
}

export default ReplyList;
